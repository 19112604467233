import React, { useEffect } from "react";
import "./styles.css";
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { createFilterOptions } from "@mui/base";
import lounge_photo_web from "../../../assets/static/images/foto_4.png";
import lounge_photo_mobile from "../../../assets/static/images/home_searcher_banner_mobile.jpg";
import { Link } from "react-router-dom";
import axios from "axios";

const selectDropdownStyle = {PaperProps:
      {sx:
        {'& .MuiMenuItem-root':
        {fontFamily: 'Nunito'
      }
    }
  }
};

function storeFilter(query) {
  sessionStorage.setItem("landing-search", JSON.stringify(query));
}

function isBlank(a) {
  return a == null || a === "" || a === undefined;
}

export default function HomeSearcher() {
  const [ubications, setUbications] = React.useState([]);
  const [propTypes, setPropTypes] = React.useState([]);
  const OPTIONS_LIMIT = 200;

  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT
  });

  useEffect(() => {
    axios.post('/RendaraWebAPI/listado/ubicaciones', { resultados: 10000 })
      .then(function (response) {setUbications(response.data);})
      .catch(function (error) {
        setUbications([]);
        console.error(
          "Ha ocurrido un error al consultar las ubicaciones ",
          error.response.status, " ", error.response.data);
      });

      axios.get('/RendaraWebAPI/listado/tipos_inmueble')
      .then(function (response) {setPropTypes(response.data);})
      .catch(function (error) {
        setPropTypes([]);
        console.error(
          "Ha ocurrido un error al consultar los tipos de inmueble ",
          error.response.status, " ", error.response.data);
      });
  }, []);

  const [ubication, setUbication] = React.useState(null);
  const [propType, setPropType] = React.useState("");
  const [budget, setBudget] = React.useState("");
  const [numberOfRooms, setNumberOfRooms] = React.useState("");
  
  let searchParameters = {
    ubicacion: isBlank(ubication) ? null : ubication,
    tipo_inmueble: isBlank(propType) ? null : propType,
    valor_canon_desde: isBlank(budget) ? null : parseInt(budget.split("-")[0]),
    valor_canon_hasta: isBlank(budget) ? null : parseInt(budget.split("-")[1]),
    num_alcobas: isBlank(numberOfRooms) ? null : parseInt(numberOfRooms)
  };
  
  return (
    <div className="searcher">
      <img className="searcherBannerImageWeb"
        src={lounge_photo_web}
        alt="Foto de una sala"/>
      <img className="searcherBannerImageMobile"
        src={lounge_photo_mobile}
        alt="Foto de una sala"/>
      <div className="searcherTitleWeb">
        Alquila, arrienda y publica inmuebles
      </div>
      <div className="searcherTitleMobile">
        El sitio perfecto para arrendar tu próximo inmueble
      </div>
      <div className="searcherSearcher">
        <div className="homeSearcherUbicationSec">
          <label>Ubicación</label>
          <Autocomplete className="ubicationAutocomplete"
            filterOptions={filterOptions}
            value={ubication}
            onChange={(e, v) => {setUbication(v);}}
            options={ubications}
            getOptionLabel={(option) => option.nombre}
            renderInput={(params) => <TextField placeholder="Ciudad o barrio" {...params}/>}
            sx={{input: {color: 'red', "&::placeholder": {opacity: 1}}}}/>
        </div>
        <div className="homeSearcherSep"></div>
        <div className="homeSearcherSelectorSec">
          <label>Tipo de inmueble</label>
          <FormControl>
            <Select className="propTypeSelector"
              displayEmpty
              MenuProps={selectDropdownStyle}
              value={propType}
              onChange={v => {setPropType(v.target.value);}}>
              <MenuItem key={""} value={""}>Selecciona...</MenuItem>
              {propTypes.map(propTypeOption =>
                <MenuItem key={propTypeOption.cod_tipo_inmueble}
                  value={propTypeOption.cod_tipo_inmueble}>
                  {propTypeOption.nom_tipo_inmueble}
                </MenuItem>)}
            </Select>
          </FormControl>
        </div>
        <div className="homeSearcherSep"></div>
        <div className="homeSearcherSelectorSec">
          <label>Presupuesto</label>
          <FormControl>
            <Select className="budgetSelector"
              displayEmpty
              MenuProps={selectDropdownStyle}
              value={budget}
              onChange={(v) => {setBudget(v.target.value);}}>
              <MenuItem value={""}>Desde y hasta</MenuItem>
              <MenuItem value={"0-600000"}>menor a 600.000</MenuItem>
              <MenuItem value={"600000-1000000"}>de 600.000 a 1.000.000</MenuItem>
              <MenuItem value={"1000000-2000000"}>de 1.000.000 a 2.000.000</MenuItem>
              <MenuItem value={"2000000-4000000"}>de 2.000.000 a 4.000.000</MenuItem>
              <MenuItem value={"4000000-8000000"}>de 4.000.000 a 8.000.000</MenuItem>
              <MenuItem value={"8000000-100000000"}>mas de 8.000.000</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="homeSearcherSep"></div>
        <div className="homeSearcherSelectorSec">
        <label>N de habitaciones</label>
          <FormControl>
            <Select className="numOfRoomSelector"
              displayEmpty
              MenuProps={selectDropdownStyle}
              value={numberOfRooms}
              onChange={(v) => {setNumberOfRooms(v.target.value);}}>
              <MenuItem value={""}>¿Cuántas quieres?</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5+</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <button className="searcherButton"
        onClick={() => storeFilter(searchParameters)}>
        <Link to="/resultado">
          Buscar
        </Link>
      </button>
    </div>
  );
}
