import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Autocomplete, TextField, Dialog, Box, FormGroup, FormControlLabel, Checkbox, FormControl, InputLabel, MenuItem, Select, createFilterOptions, Button } from '@mui/material';
import ReactMapboxGl, { Marker } from 'react-mapbox-gl';
import axios from "axios";
import Property from "../Property";
import SearchFilter from "../SearchFilter";
import { FormatUtils } from "../Utils/FormatUtils";
import ClearIcon from '@mui/icons-material/Clear';
import { configuration } from "../../../config";
import useWindowDimensions from "../Utils/WindowsDimensions";
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';


import "./styles.css";
import 'mapbox-gl/dist/mapbox-gl.css';

const Map = ReactMapboxGl({
  accessToken: configuration.mapboxToken,
});

let mapa = null;
let t_mapacentro = null;
let t_mapazoom = null;
let t_activarAjuste = true;

let ESTRATOS = {
  "1": "UNO",
  "2": "DOS",
  "3": "TRES",
  "4": "CUATRO",
  "5": "CINCO",
  "6": "SEIS",
  "7": "COMERCIAL",
  "8": "SIN ASIGNAR"
};

let ESTADOS_FISICOS = {
  "EXC": "EXCELENTE",
  "BUE": "BUENO",
  "REG": "REGULAR",
  "MAL": "MALO",
  "NUV": "ESTRENAR",
  "ARR": "REFORMADO"
}

let TIPOS_PARQUEADERO = {
  "1": "DOBLE",
  "2": "SENCILLO",
  "DPA": "DOS PARQUEADEROS",
  "3": "CUBIERTO",
  "4": "DESCUBIERTO",
  "5": "COMUNAL",
  "6": "CON SERVIDUMBRE"
}

let SERVICIOS_PUBLICOS = {
  "AGU": "AGUA",
  "LUZ": "LUZ",
  "GAS": "GAS"
}

// ------------------------------------ Funciones utilitarias

function isBlank(a) { return a === null || a === "" || a === undefined; }

function isNotBlank(a) { return !isBlank(a) }

function gradosARadianes(grados) { return grados * Math.PI / 180; };

function distancia([lon1, lat1], [lon2, lat2]) {

  lat1 = gradosARadianes(lat1);
  lon1 = gradosARadianes(lon1);
  lat2 = gradosARadianes(lat2);
  lon2 = gradosARadianes(lon2);

  const RADIO_TIERRA_EN_KILOMETROS = 6371;
  let diferenciaEntreLongitudes = (lon2 - lon1);
  let diferenciaEntreLatitudes = (lat2 - lat1);
  let a = Math.pow(Math.sin(diferenciaEntreLatitudes / 2.0), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(diferenciaEntreLongitudes / 2.0), 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return RADIO_TIERRA_EN_KILOMETROS * c;
}

function clonar(obj) { if (obj === undefined) return null; return JSON.parse(JSON.stringify(obj)) }

// --------------------------------------------------------------

export default function SearchResult() {

  const navigate = useNavigate();

  const { isMovil } = useWindowDimensions();

  const [verSoloMapa, setVerSoloMapa] = React.useState(false);

  // --------------------------------------------------------------
  // Se obtienen las listas por defecto

  const [ubicaciones, setUbicaciones] = React.useState([]);
  const [tiposInmueble, setTiposInmueble] = React.useState([]);
  const [presupuestos, setPresupuestos] = React.useState([
    { value: "0-600000", label: "menor a 600.000" },
    { value: "600000-1000000", label: "de 600.000 a 1.000.000" },
    { value: "1000000-2000000", label: "de 1.000.000 a 2.000.000" },
    { value: "2000000-4000000", label: "de 2.000.000 a 4.000.000" },
    { value: "4000000-8000000", label: "de 4.000.000 a 8.000.000" },
    { value: "8000000-2000000000", label: "mas de 8.000.000" }
  ]);

  const [numerosHabitaciones, setNumerosHabitaciones] = React.useState([
    { value: "1", label: "una habitación" },
    { value: "2", label: "2 habitaciones" },
    { value: "3", label: "3 habitaciones" },
    { value: "4", label: "4 habitaciones" },
    { value: "5", label: "5 o mas habitaciones" },
  ]);

  const [mapaProps, setMapaProps] = React.useState({ centro: [-74.0815831, 4.6459286], zoom: 5 });
  const [actualizarAlMover, setActualizarAlMover] = React.useState(false);
  const [ordenamiento, setOrdenamiento] = React.useState("destacado.desc");
  const [filterData, setfilterData] = React.useState({});
  const [filtrosAdicionales, setFiltrosAdicionales] = React.useState([]);

  useEffect(() => {

    if (!isMovil && verSoloMapa) {
      setVerSoloMapa(false);
    }

  }, [isMovil]);

  useEffect(() => {

    axios.post('/RendaraWebAPI/listado/ubicaciones', { resultados: 10000 })
      .then(function (response) { setUbicaciones(response.data); })
      .catch(function (error) { setUbicaciones([]); });

    axios.get('/RendaraWebAPI/listado/tipos_inmueble')
      .then(function (response) { setTiposInmueble(response.data); })
      .catch(function (error) {
        setTiposInmueble([]);
      });

  }, []);

  // --------------------------------------------------------------
  // valores seleccionados

  const [cargando, setCargando] = React.useState(false);
  const [ubicacion, setUbicacion] = React.useState(null);
  const [tipoInmueble, setTipoInmueble] = React.useState("");
  const [presupuesto, setPresupuesto] = React.useState("");
  const [numeroHabitaciones, setNumeroHabitaciones] = React.useState("");

  const [inmuebles, setInmuebles] = React.useState([]);
  const [coordenadas, setCoordenadas] = React.useState(null);

  const [openFiltro, setopenFiltro] = React.useState(false);

  // -------------------------------------------------------------

  useEffect(() => {

    if (openFiltro || cargando) {
      return;
    }

    let fadicionales = [];
    let id = 0;

    // baños 
    if (isNotBlank(filterData.num_banos)) {
      let etiquetas = ["un baño", "2 baños", "3 baños", "4 baños", "5 baños o mas"];
      let banos = filterData.num_banos.filter(b => { return b <= 5; });
      banos.forEach((e, i) => {
        fadicionales.push({ id: id++, arreglo: "num_banos", etiqueta: etiquetas[e - 1], valor: e, adicionar: e == 5 });
      });
    }

    // num parqueaderos 
    if (isNotBlank(filterData.num_parqueaderos)) {
      let etiquetas = ["un parqueadero", "2 parqueaderos", "3 parqueaderos o mas"];
      let parqu = filterData.num_parqueaderos.filter(b => { return b <= 3; });
      parqu.forEach((e, i) => {
        fadicionales.push({ id: id++, arreglo: "num_parqueaderos", etiqueta: etiquetas[e - 1], valor: e, adicionar: e == 3 });
      });
    }

    // estratos 
    if (isNotBlank(filterData.estratos)) {
      filterData.estratos.forEach((e, i) => {
        fadicionales.push({ id: id++, arreglo: "estratos", etiqueta: "Estrato " + ESTRATOS[e], valor: e, adicionar: false });
      });
    }

    // estado fisico 
    if (isNotBlank(filterData.estado_fisico)) {
      filterData.estado_fisico.forEach((e, i) => {
        fadicionales.push({ id: id++, arreglo: "estado_fisico", etiqueta: "Estado " + ESTADOS_FISICOS[e], valor: e, adicionar: false });
      });
    }

    // tipos_parqueadero
    if (isNotBlank(filterData.tipos_parqueadero)) {
      filterData.tipos_parqueadero.forEach((e, i) => {
        fadicionales.push({ id: id++, arreglo: "tipos_parqueadero", etiqueta: "Parqueadero " + TIPOS_PARQUEADERO[e], valor: e, adicionar: false });
      });
    }

    // servicios
    if (isNotBlank(filterData.servicios)) {
      filterData.servicios.forEach((e, i) => {
        fadicionales.push({ id: id++, arreglo: "servicios", etiqueta: "Servicio " + SERVICIOS_PUBLICOS[e], valor: e, adicionar: false });
      });
    }

    // administracion_incluida
    if (isNotBlank(filterData.administracion_incluida)) {
      fadicionales.push({ id: id++, variable: "administracion_incluida", etiqueta: (filterData.administracion_incluida ? "Con " : "Sin ") + "administración incluida", valor: filterData.administracion_incluida, adicionar: false });
    }

    // valor_canon_administracion_desde
    if (isNotBlank(filterData.valor_canon_administracion_desde)) {
      fadicionales.push({ id: id++, variable: "valor_canon_administracion_desde", etiqueta: "Canon administracion desde: " + FormatUtils.currency(filterData.valor_canon_administracion_desde), valor: filterData.valor_canon_administracion_desde, adicionar: false });
    }

    // valor_canon_administracion_hasta
    if (isNotBlank(filterData.valor_canon_administracion_hasta)) {
      fadicionales.push({ id: id++, variable: "valor_canon_administracion_hasta", etiqueta: "Canon administracion hasta: " + FormatUtils.currency(filterData.valor_canon_administracion_hasta), valor: filterData.valor_canon_administracion_hasta, adicionar: false });
    }

    // area_construida_desde
    if (isNotBlank(filterData.area_construida_desde)) {
      fadicionales.push({ id: id++, variable: "area_construida_desde", etiqueta: "Area construida desde: " + filterData.area_construida_desde + " m2", valor: filterData.area_construida_desde, adicionar: false });
    }

    // area_construida_hasta
    if (isNotBlank(filterData.area_construida_hasta)) {
      fadicionales.push({ id: id++, variable: "area_construida_hasta", etiqueta: "Area construida hasta: " + filterData.area_construida_hasta + " m2", valor: filterData.area_construida_hasta, adicionar: false });
    }

    // acepta_mascotas
    if (isNotBlank(filterData.acepta_mascotas)) {
      fadicionales.push({ id: id++, variable: "acepta_mascotas", etiqueta: (filterData.acepta_mascotas ? "Acepta " : "No acepta ") + "mascotas", valor: filterData.acepta_mascotas, adicionar: false });
    }

    // antiguedad 
    if (isNotBlank(filterData.antiguedad)) {
      filterData.antiguedad.forEach((e, i) => {
        fadicionales.push({ id: id++, arreglo: "antiguedad", etiqueta: "Antiguedad de " + e.desde + " a " + e.hasta + " años", valor: e, adicionar: false });
      });
    }

    setFiltrosAdicionales(fadicionales);

  }, [filterData]);

  function eliminarFiltrosAdicionales(ea) {
    setFiltrosAdicionales(clonar(filtrosAdicionales).filter(e => { return e.id != ea.id }));
  }

  // ------------------------------------------------------------- Acciones al cambiar de ubicacion

  const [filtrosUbicacion, setFiltrosUbicacion] = React.useState([]);

  useEffect(() => {

    if (ubicacion == null) { return; }
    setUbicacion(null);

    let encontrado = filtrosUbicacion.find(e => e.cod_ciudad === ubicacion.cod_ciudad && e.cod_barrio === ubicacion.cod_barrio) !== undefined;
    if (encontrado) { return; }

    let f = clonar(filtrosUbicacion);
    f.push(clonar(ubicacion));
    setFiltrosUbicacion(f);

  }, [ubicacion])

  function eliminarFiltroUbicacion(fu) {
    setFiltrosUbicacion(clonar(filtrosUbicacion.filter(e => e.cod_ciudad !== fu.cod_ciudad || e.cod_barrio !== fu.cod_barrio)));
  }

  // ------------------------------------------------------------- Acciones al cambiar de tipo de inmueble

  const [filtrosTipoInmueble, setFiltrosTipoInmueble] = React.useState([]);

  useEffect(() => {

    if (tipoInmueble == "") { return; }
    setTipoInmueble("");

    let encontrado = filtrosTipoInmueble.find(e => e.cod_tipo_inmueble === tipoInmueble) !== undefined;
    if (encontrado) { return; }

    let f = clonar(filtrosTipoInmueble);
    f.push(clonar(tiposInmueble.find(t => t.cod_tipo_inmueble === tipoInmueble)));
    setFiltrosTipoInmueble(f);

  }, [tipoInmueble])

  function eliminarFiltroTipoInmueble(fu) {
    setFiltrosTipoInmueble(clonar(filtrosTipoInmueble.filter(e => e.cod_tipo_inmueble !== fu.cod_tipo_inmueble)));
  }

  // ------------------------------------------------------------- Acciones al cambiar de presupuesto

  const [filtrosPresupuesto, setFiltrosPresupuesto] = React.useState([]);

  useEffect(() => {

    if (presupuesto == "") { return; }
    setPresupuesto("");

    let encontrado = filtrosPresupuesto.find(e => e.value === presupuesto) !== undefined;
    if (encontrado) { return; }

    let f = clonar(filtrosPresupuesto);
    f.push(clonar(presupuestos.find(t => t.value === presupuesto)));
    setFiltrosPresupuesto(f);

  }, [presupuesto])

  function eliminarFiltroPresupuesto(fu) {
    setFiltrosPresupuesto(clonar(filtrosPresupuesto.filter(e => e.value !== fu.value)));
  }

  // ------------------------------------------------------------- Acciones al cambiar de nuemro de habitaciones

  const [filtrosNumeroHabitaciones, setFiltrosNumeroHabitaciones] = React.useState([]);

  useEffect(() => {

    if (numeroHabitaciones == "") { return; }
    setNumeroHabitaciones("");

    let encontrado = filtrosNumeroHabitaciones.find(e => e.cod_tipo_inmueble === tipoInmueble) !== undefined;
    if (encontrado) { return; }

    let f = clonar(filtrosNumeroHabitaciones);
    f.push(clonar(numerosHabitaciones.find(t => t.value === numeroHabitaciones)));
    setFiltrosNumeroHabitaciones(f);

  }, [numeroHabitaciones])

  function eliminarFiltrosNumeroHabitaciones(fu) {
    setFiltrosNumeroHabitaciones(clonar(filtrosNumeroHabitaciones.filter(e => e.value !== fu.value)));
  }

  // -------------------------------------------------------------



  useEffect(() => {

    let p_ordenamiento = ordenamiento.split(".");

    let consulta = {
      sort: p_ordenamiento[0],
      order: p_ordenamiento[1],
      from: 0,
      size: 200
    }

    if (actualizarAlMover && coordenadas != null) {
      consulta.latitud = coordenadas[1];
      consulta.longitud = coordenadas[0];
    } else {

      if (filtrosUbicacion != null) {
        consulta.ubicaciones = filtrosUbicacion.map(f => { return { cod_ciudad: f.cod_ciudad, cod_barrio: f.cod_barrio } });
      }

    }

    if (filtrosTipoInmueble.length > 0) {
      consulta.tipos_inmueble = filtrosTipoInmueble.map(f => { return f.cod_tipo_inmueble });
    }

    if (filtrosPresupuesto.length > 0) {
      consulta.valor_canon = filtrosPresupuesto.map(f => { return { desde: parseInt(f.value.split("-")[0]), hasta: parseInt(f.value.split("-")[1]) } });
    }

    if (filtrosNumeroHabitaciones.length > 0) {
      consulta.num_alcobas = filtrosNumeroHabitaciones.map(f => { return parseInt(f.value) });

      if (consulta.num_alcobas.includes(5)) {
        for (let i = 6; i <= 80; i++) {
          consulta.num_alcobas.push(i);
        }
      }

    }

    filtrosAdicionales.forEach(e => {

      if (e.variable !== undefined) {
        consulta[e.variable] = e.valor;
      }

      if (e.arreglo !== undefined) {

        if (consulta[e.arreglo] === undefined) {
          consulta[e.arreglo] = [];
        }

        consulta[e.arreglo].push(e.valor);

        if (e.adicionar) {
          for (let i = 1; i <= 50; i++) {
            consulta[e.arreglo].push(parseInt(e.valor) + i);
          }
        }

      }

    });

    if (mapa != null) {
      let c = mapa.getCenter();
      t_mapacentro = [c.lng, c.lat];
      t_mapazoom = mapa.getZoom();
    }

    setCargando(true);
    setInmuebles([]);

    setfilterData({
      num_banos: consulta.num_banos,
      estratos: consulta.estratos,
      administracion_incluida: consulta.administracion_incluida,
      valor_canon_administracion_desde: consulta.valor_canon_administracion_desde,
      valor_canon_administracion_hasta: consulta.valor_canon_administracion_hasta,
      area_construida_desde: consulta.area_construida_desde,
      area_construida_hasta: consulta.area_construida_hasta,
      estado_fisico: consulta.estado_fisico,
      antiguedad: consulta.antiguedad,
      num_parqueaderos: consulta.num_parqueaderos,
      tipos_parqueadero: consulta.tipos_parqueadero,
      servicios: consulta.servicios,
      acepta_mascotas: consulta.acepta_mascotas
    });

    axios.post('/RendaraWebAPI/inmueble/consulta', consulta)
      .then(function (response) {
        if (t_mapacentro != null) {
          setMapaProps({ centro: t_mapacentro, zoom: t_mapazoom });
        }
        setCargando(false);
        let _inmuebles = response.data;

        _inmuebles.forEach(inm => {
          if (inm.latitud_inmueble != null && (inm.latitud_inmueble > 89 || inm.latitud_inmueble < -89)) inm.latitud_inmueble = null;
          if (inm.longitud_inmueble != null && (inm.longitud_inmueble > 179 || inm.longitud_inmueble < -179)) inm.longitud_inmueble = null;
        });

        setInmuebles(_inmuebles);

      })
      .catch(function (error) { setInmuebles([]); });

  }, [ordenamiento, filtrosUbicacion, filtrosTipoInmueble, filtrosPresupuesto, filtrosNumeroHabitaciones, coordenadas, filtrosAdicionales]);


  useEffect(() => {

    let a = sessionStorage.getItem("landing-search");

    if (a != null && tiposInmueble.length > 0) {
      let filtros = JSON.parse(a);
      if (filtros.ubicacion != null) { setFiltrosUbicacion([filtros.ubicacion]); }
      if (filtros.tipo_inmueble != null) { setFiltrosTipoInmueble(tiposInmueble.filter(f => { return f.cod_tipo_inmueble === filtros.tipo_inmueble })); }
      if (filtros.num_alcobas != null) { setFiltrosNumeroHabitaciones(numerosHabitaciones.filter(f => { return f.value === "" + filtros.num_alcobas })); }
      if (filtros.valor_canon_desde != null) { setFiltrosPresupuesto(presupuestos.filter(f => { return f.value == filtros.valor_canon_desde + "-" + filtros.valor_canon_hasta })); }
      sessionStorage.removeItem("landing-search");
    }

  }, [tiposInmueble]);

  // -----------------------------------------------------------------

  function centrarMapa() {

    if (!t_activarAjuste) { return; }

    if (mapa === null || inmuebles.length === 0) { return; }

    let minLog = 179;
    let maxLog = -179;

    let minLat = 89;
    let maxLat = -89;

    inmuebles.forEach(e => {
      if (e.longitud_inmueble != null) {
        if (minLog > e.longitud_inmueble) minLog = e.longitud_inmueble;
        if (maxLog < e.longitud_inmueble) maxLog = e.longitud_inmueble;
      }

      if (e.latitud_inmueble != null) {
        if (minLat > e.latitud_inmueble) minLat = e.latitud_inmueble;
        if (maxLat < e.latitud_inmueble) maxLat = e.latitud_inmueble;
      }
    })

    mapa.fitBounds([
      [minLog, maxLat],
      [maxLog, minLat]
    ], { padding: 60 })

  }

  // -------------------------------------------

  function Filtros() {
    return <div className="area_filtros">
      {filtrosUbicacion.map(fu =>
        <div key={fu.cod_ciudad + "-" + fu.cod_barrio} className="item_filtro">
          {fu.nombre}
          <button onClick={() => { eliminarFiltroUbicacion(fu) }}> <ClearIcon /> </button>
        </div>
      )}

      {filtrosTipoInmueble.map(fu =>
        <div key={fu.cod_tipo_inmueble} className="item_filtro">
          {fu.nom_tipo_inmueble}
          <button onClick={() => { eliminarFiltroTipoInmueble(fu) }}> <ClearIcon /> </button>
        </div>
      )}

      {filtrosPresupuesto.map(fu =>
        <div key={fu.value} className="item_filtro">
          {fu.label}
          <button onClick={() => { eliminarFiltroPresupuesto(fu) }}> <ClearIcon /> </button>
        </div>
      )}

      {filtrosNumeroHabitaciones.map(fu =>
        <div key={fu.value} className="item_filtro">
          {fu.label}
          <button onClick={() => { eliminarFiltrosNumeroHabitaciones(fu) }}> <ClearIcon /> </button>
        </div>
      )}

      {filtrosAdicionales.map(fu =>
        <div key={fu.id} className="item_filtro">
          {fu.etiqueta}
          <button onClick={() => { eliminarFiltrosAdicionales(fu) }}> <ClearIcon /> </button>
        </div>
      )}

    </div>
  }

  // -------------------------------------------

  function Buscador() {

    const filterOptions = createFilterOptions({ limit: 200, ignoreAccents: true, ignoreCase: true });

    return <>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        columnSpacing={2}
      >
        <Grid item xs={isMovil ? 12 : 4} className="inputArea">
          <Autocomplete
            className="pInput"
            filterOptions={filterOptions}
            value={ubicacion}
            onChange={(e, v) => { t_activarAjuste = true; setUbicacion(v); setCoordenadas(null) }}
            options={ubicaciones}
            getOptionLabel={(option) => option.nombre}
            renderInput={(params) => <TextField {...params} label="Ubicación" />}
            size="small"
          />
        </Grid>

        <Grid item xs={isMovil ? 6 : 2} className="inputArea">

          <FormControl fullWidth size="small">
            <InputLabel>Tipo de Inmueble</InputLabel>
            <Select
              className="pInput"
              value={tipoInmueble}
              label="Tipo de inmueble"
              onChange={e => { t_activarAjuste = true; setTipoInmueble(e.target.value) }}

            >
              <MenuItem value={""}>-- TODOS --</MenuItem>
              {tiposInmueble.map(tipo =>
                <MenuItem key={tipo.cod_tipo_inmueble} value={tipo.cod_tipo_inmueble}>{tipo.nom_tipo_inmueble}</MenuItem>
              )}

            </Select>
          </FormControl>

        </Grid>

        <Grid item xs={isMovil ? 6 : 2} className="inputArea">

          <FormControl fullWidth size="small">
            <InputLabel>Presupuesto</InputLabel>
            <Select
              className="pInput"
              value={presupuesto}
              label="Presupuesto"
              onChange={e => { t_activarAjuste = true; setPresupuesto(e.target.value) }}
            >
              <MenuItem value={""}>-- TODOS --</MenuItem>
              {presupuestos.map(p =>
                <MenuItem key={p.value} value={p.value}>{p.label}</MenuItem>
              )}

            </Select>
          </FormControl>

        </Grid>

        <Grid item xs={isMovil ? 6 : 2} className="inputArea">

          <FormControl fullWidth size="small">
            <InputLabel># de Habitaciones</InputLabel>
            <Select
              className="pInput"
              value={numeroHabitaciones}
              label="# de Habitaciones"
              onChange={e => { t_activarAjuste = true; setNumeroHabitaciones(e.target.value) }}
            >
              <MenuItem value={""}>-- TODOS --</MenuItem>
              {numerosHabitaciones.map(n =>
                <MenuItem key={n.value} value={n.value}>{n.label}</MenuItem>
              )}

            </Select>
          </FormControl>

        </Grid>

        <Grid item xs={isMovil ? 6 : 2} className="inputArea">
          <div className="separador">
            <button className="btn_filtro" onClick={() => {
              setopenFiltro(true);
            }}>+ Filtro </button>
          </div>
        </Grid>

      </Grid>

      <Dialog maxWidth="md" onClose={() => { setopenFiltro(false) }} open={openFiltro}>
        <Box >
          <SearchFilter filterData={filterData} setfilterData={d => { setopenFiltro(false); setfilterData(d); }} />
        </Box>
      </Dialog>

    </>
  }

  // -----------------------------------------------------------

  function Resultados() {
    return <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        className="sr_resultado_header"

      >
        {isMovil &&
          <Grid item xs={isMovil ? 4 : 6}>
            <Button variant="text" className="btn_map" onClick={() => { setVerSoloMapa(true); }}>
              <MapOutlinedIcon size="small" /><span>Ver mapa</span>
            </Button>
          </Grid>
        }

        <Grid item xs={isMovil ? 1 : 6}>
        </Grid>

        <Grid item xs={isMovil ? 7 : 6}>
          <FormControl fullWidth size="small">
            <InputLabel>Ordenar por:</InputLabel>
            <Select
              value={ordenamiento}
              label="Ordenar por:"
              onChange={e => { t_activarAjuste = false; setOrdenamiento(e.target.value) }}
            >
              <MenuItem value={"destacado.desc"}>Destacados</MenuItem>
              <MenuItem value={"valor_canon_arrendamiento_numeros.desc"}>Mayor Precio</MenuItem>
              <MenuItem value={"valor_canon_arrendamiento_numeros.asc"}>Menor Precio</MenuItem>
              <MenuItem value={"area_construida.desc"}>Mayor área construida</MenuItem>
              <MenuItem value={"area_construida.asc"}>Menor área construida</MenuItem>
              <MenuItem value={"num_alcobas.desc"}>Mayor cantidad de habitaciones</MenuItem>
              <MenuItem value={"num_alcobas.asc"}>Menor cantidad de habitaciones</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <div className="sr_resultado_area">

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          rowSpacing={0}
          columnSpacing={1}
        >
          {inmuebles.map(inmueble =>
            <Grid item key={inmueble.codigo_inmueble}>
              <div className="propiedad" id={"Property_" + inmueble.codigo_inmueble} onMouseEnter={() => { seleccionarInmueble(inmueble.codigo_inmueble) }}>
                <Property inmueble={inmueble} />
              </div>
            </Grid>
          )}

          {inmuebles.length === 0 &&
            <>
              {actualizarAlMover ?
                <h2>Sin Resultados en la zona</h2>
                :
                <h2>Sin Resultados</h2>}
            </>
          }

        </Grid>

      </div>

    </>
  }

  // -----------------------------------------------------------

  function seleccionarInmueble(cod_inmueble) {

    try {

      let elems = Array.from(document.getElementsByClassName("propiedad_seleccionado"));
      elems.forEach(c => { c.classList.remove("propiedad_seleccionado"); });

      let cp = document.getElementById("Property_" + cod_inmueble);
      cp.classList.add("propiedad_seleccionado");

      let c = document.getElementById("Marker_" + cod_inmueble);
      c.classList.add("propiedad_seleccionado");

    } catch (error) {
    }

  }

  function mostrarInmueble(cod_inmueble) {

    if (isMovil) {
      navigate("/detalle/" + cod_inmueble);
    } else {
      let cp = document.getElementById("Property_" + cod_inmueble);
      cp.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  function Mapa() {

    return <Map

      style="mapbox://styles/mapbox/streets-v9"
      containerStyle={{
        height: isMovil ? '100%' : "calc(100% - 40px)",
        width: '100%'
      }}

      center={mapaProps.centro}
      zoom={[mapaProps.zoom]}

      onStyleLoad={async (map) => {
        mapa = map;
        map.doubleClickZoom.disable();
        map.dragRotate.disable();
        centrarMapa();
      }}

      onMouseUp={(map) => {
        if (actualizarAlMover) {
          let c = map.getCenter();
          if (map.getZoom() > 12 && (coordenadas == null || distancia([c.lng, c.lat], coordenadas) > 1.5)) {
            t_activarAjuste = false;
            t_mapacentro = [c.lng, c.lat];
            t_mapazoom = map.getZoom();
            setMapaProps({ centro: t_mapacentro, zoom: t_mapazoom });
            setCoordenadas([c.lng, c.lat]);
          }
        }
      }}

    >

      {inmuebles.map(inmueble => inmueble.longitud_inmueble != null && inmueble.latitud_inmueble != null &&
        <Marker key={inmueble.codigo_inmueble} coordinates={[inmueble.longitud_inmueble, inmueble.latitud_inmueble]} anchor="bottom">
          <div
            className="marcador_inmueble"
            id={"Marker_" + inmueble.codigo_inmueble}
            onMouseEnter={() => { seleccionarInmueble(inmueble.codigo_inmueble) }}
            onClick={() => { mostrarInmueble(inmueble.codigo_inmueble) }}>
            {FormatUtils.currencyM(inmueble.valor_canon_arrendamiento_numeros)}
          </div>
        </Marker>
      )}

    </Map>
  }

  // -----------------------------------------------------------

  return (
    <>
      {verSoloMapa &&
        <>
          <Mapa />
          <Button
            variant="contained"
            className="btn_ocultar_mapa"
            onClick={() => { setVerSoloMapa(false) }}
          >Ocultar mapa</Button>
        </>
      }

      {(!verSoloMapa) &&
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
          className="SearchResult">

          <Grid item xs="auto" className="sr_buscador">
            <Buscador />
            <Filtros />
          </Grid>

          <Grid item xs container direction="row" justifyContent="center" alignItems="stretch">

            <Grid item xs={isMovil ? 12 : 5} className="sr_resultado">
              {!cargando && <Resultados />}
            </Grid>

            <Grid item xs={isMovil ? 12 : 7} className="sr_area_mapa">
              {(!isMovil || verSoloMapa) &&
                <>
                  <div className="sr_emapa">
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox
                          checked={actualizarAlMover}
                          onChange={e => {
                            t_activarAjuste = false;
                            let c = mapa.getCenter();
                            t_mapacentro = [c.lng, c.lat];
                            t_mapazoom = mapa.getZoom();
                            setMapaProps({ centro: t_mapacentro, zoom: t_mapazoom });

                            setActualizarAlMover(e.target.checked);
                          }}
                        />}
                        label="Buscar mientras exploro en el mapa" />
                    </FormGroup>
                  </div>
                  <Mapa />
                </>
              }
            </Grid>

          </Grid>

        </Grid>
      }
    </>

  );
}
