import React from "react";
import { Box, Dialog } from '@mui/material';
import "./styles.css";
import { configuration } from "../../../config.js";
import CloseIcon from '@mui/icons-material/Close';
import touchscreen from "../../../assets/static/icons/be_our_partner_i1.svg";
import income from "../../../assets/static/icons/be_our_partner_i2.svg";
import payment from "../../../assets/static/icons/be_our_partner_i3.svg";
import location from "../../../assets/static/icons/be_our_partner_i4.svg";
import be_our_partner_p1 from "../../../assets/static/images/be_our_partner_p1.jpg";
import be_our_partner_p2 from "../../../assets/static/images/be_our_partner_p2.jpg";
import be_our_partner_p3 from "../../../assets/static/images/be_our_partner_p3.jpg";
import be_our_partner_p4 from "../../../assets/static/images/be_our_partner_p4.jpg";
import useWindowDimensions from "../Utils/WindowsDimensions";

const WhyPostWithUs = () => {

  const { isMovil } = useWindowDimensions();

  const [openFiltro, setopenFiltro] = React.useState(false);

  return (
    <div className="beOurPartnerContainer">
      <div className="beOurPartnerContTitle">
        <p>Conoce nuestros beneficios</p>
      </div>
      <div className="beOurPartnerSubCont1">
        <div className="beOurPartnerImgCont">
          <div className="beOurPartnerImg"><img src={be_our_partner_p1} alt="" /></div>
        </div>
        <div className="beOurPartnerTextCont">
          <img className="beOurPartnerTextImg"
            src={touchscreen} alt="" />
          <p className="beOurPartnerTitleCont">
            Te damos tecnología
          </p>
          <p className="beOurPartnerText">
            Tienes la posibilidad de utilizar un software donde puedes
            interactuar para conseguir, hacer o completar nuevos trabajos.
          </p>
        </div>
      </div>
      <div className="beOurPartnerSubCont2">
        <div className="beOurPartnerImgCont">
          <div className="beOurPartnerImg"><img src={be_our_partner_p2} alt="" /></div>
        </div>
        <div className="beOurPartnerTextCont">
          <img className="beOurPartnerTextImg"
            src={income} alt="" />
          <p className="beOurPartnerTitleCont">
            Incrementa tus ingresos
          </p>
          <p className="beOurPartnerText">
            Al trabajar con nosotros podrás acceder a nuevos clientes y
            obtener una entrada segura y constante.
          </p>
        </div>
      </div>
      <div className="beOurPartnerSubCont3">
        <div className="beOurPartnerImgCont">
          <div className="beOurPartnerImg"><img src={be_our_partner_p3} alt="" /></div>
        </div>
        <div className="beOurPartnerTextCont">
          <img className="beOurPartnerTextImg"
            src={payment} alt="" />
          <p className="beOurPartnerTitleCont">
            Asegura la recepción del pago por tu colaboración
          </p>
          <p className="beOurPartnerText">
            Cambia la forma en como gestionas tus pagos, con nosotros
            podrás estar tranquilo al realizar tu trabajo y recibir
            puntualmente tu dinero.
          </p>
        </div>
      </div>
      <div className="beOurPartnerSubCont4">
        <div className="beOurPartnerImgCont">
          <div className="beOurPartnerImg"><img src={be_our_partner_p4} alt="" /></div>
        </div>
        <div className="beOurPartnerTextCont">
          <img className="beOurPartnerTextImg"
            src={location} alt="" />
          <p className="beOurPartnerTitleCont">
            Sé parte del cambio del sector inmobiliario
          </p>
          <p className="beOurPartnerText">
            Creemos que lo que la tecnología propicia es increíblemente
            importante y tú puedes hacer parte de ese cambio, te necesitamos.
          </p>
        </div>
      </div>
      <div className="beOurPartnerButtonCont">
        <button className="beOurPartnerButton"
          onClick={() => { setopenFiltro(true) }}>
          Quieres saber más
        </button>
        <Dialog fullScreen={isMovil} maxWidth={isMovil ? false : "lg"} onClose={() => { setopenFiltro(false) }} open={openFiltro}>
          <Box className="beOurPartnerDialog">
            <a className="closebtn" onClick={() => { setopenFiltro(false) }}><CloseIcon fontSize="large" /></a>
            <iframe src={configuration.formularioAliados} className="beOurPartnerDialogIframe" />
          </Box>
        </Dialog>


      </div>

    </div>
  );
};

export default WhyPostWithUs;
