import React from "react";
import "./styles.css";
import { configuration } from "../../../config";
import pyp_banner_p1 from "../../../assets/static/images/foto_2.png"
import pyp_banner_p2 from "../../../assets/static/images/foto_2_mobile.png"

export default function PostYourPropertyBanner() {
  return (
    <div>
      <div className="pYPBannerWeb">
        <div className="pYPImgDiv"><img className="pYPImg" src={pyp_banner_p1} alt="" /></div>
        <div className="pYPTitle">
          Descubre la tranquilidad de tener todo bajo control
        </div>
        <button
          className="MuiButton-root pYPButton"
          onClick={() => {
            window.open(configuration.snaptask);
          }}
        >
          Quiero publicar mi inmueble
        </button>
      </div>
      <div className="pYPBannerMobile">
        <div className="pYPImgDiv"><img className="pYPImg" src={pyp_banner_p2} alt="" /></div>
        <div className="pYPTitle">
          Descubre la tranquilidad de tener todo bajo control
        </div>
        <button
          className="MuiButton-root pYPButton"
          onClick={() => {
            window.open(configuration.snaptask);
          }}
        >
          Quiero publicar mi inmueble
        </button>
      </div>
    </div>
  );
}
