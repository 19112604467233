import React, { useState, useEffect } from 'react';
import SearchResult from "../components/SearchResult";

import "../../assets/styles/Result.css";

function Result() {

  const [coordenadas, setCoordenadas] = useState({ latitud: 4.6459125, longitud: -74.0815294 });

  useEffect(() => {
  }, []);

  return (
    <div className='Result'>
     <SearchResult/>
    </div>
  );
}

export default Result;
