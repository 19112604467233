import React, { useState, useEffect } from "react";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { FavoriteUtils } from "../Utils/FavoriteUtils";
import bed from "../../../assets/static/icons/icon_bed.svg"
import shower from "../../../assets/static/icons/icon_shower.svg"
import area from "../../../assets/static/icons/icon_area.svg"
import parking from "../../../assets/static/icons/icon_parking.svg"
import icon_corazonoff_movil from "../../../assets/static/icons/icon_corazonoff.svg"
import icon_corazonon from "../../../assets/static/icons/icon_corazonon.svg"
import photo_not_found from "../../../assets/static/images/image not found.png"
import { FormatUtils } from "../Utils/FormatUtils";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "./styles.css";

const Property = ({ inmueble }) => {

  const [isfavorito, setIsFavorito] = useState(FavoriteUtils.is(inmueble.codigo_inmueble));

  useEffect(() => {
    FavoriteUtils.set(inmueble.codigo_inmueble, isfavorito);
  }, [isfavorito]);

  return (
    <div className="property-card-container">
      <div className="pc_btn_favorito">
        <a onClick={() => { setIsFavorito(!isfavorito) }}>
          <Tooltip title={isfavorito ? "Quitar de favoritos" : "Marcar como favorito"} placement="top">
            <img src={isfavorito ? icon_corazonon : icon_corazonoff_movil} alt="favorito" />
          </Tooltip>
        </a>
      </div>
      {inmueble.fotos?.length > 0 &&
        <>
          <Swiper pagination={true} navigation={true} modules={[Pagination, Navigation]} className="swiper-property-card">
            {inmueble.fotos.map(foto =>
              <SwiperSlide key={foto.orden}>
                <div className='property-card-img'>
                  <img src={foto.ruta} alt="inmueble-foto" />
                </div>
              </SwiperSlide>
            )
            }
          </Swiper>
        </>
      }
      {inmueble.fotos == null &&
        <>
          <div className='property-card-img'>
            <img src={photo_not_found} alt="foto-not-found" />
          </div>
        </>
      }
      <section className="informacion_inmueble">
        <section className="section_info_general">
          <div className="precio_tipo">
            <h1>
              ${FormatUtils.currency(inmueble.valor_canon_arrendamiento_numeros)}
            </h1>
            <p>
              {inmueble.tipo_inmueble.nombre == null ? "W/O" : inmueble.tipo_inmueble.nombre}
            </p>
          </div>
          <p className="administracion_info">
            Administración:
            <br />
            {inmueble.valor_canon_incluye_administracion ? "incluida" :  "$" + FormatUtils.currency(inmueble.valor_canon_administracion_numeros) }
          </p>
        </section>
        <section>
          <div className="ubicacion_inmueble">
            {inmueble.barrio_inmueble?.nombre}
          </div>
          <div className="ciudad_inmueble">
            {inmueble.ciudad_inmueble.nombre}
          </div>
        </section>
        <section className="section_info_distribucion">
          <span className="bed_info_container"><img src={bed} alt="habitaciones" />{inmueble.num_alcobas}</span>
          <span className="shower_info_container"><img src={shower} alt="baños" />{inmueble.num_banos}</span>
          <span className="area_info_container"><img src={area} alt="area en metros cuadrados" />{inmueble.area_construida + " " + "m²"}</span>
          <span className="parking_info_container"><img src={parking} alt="parqueadero" />{inmueble.num_parqueadero}</span>
        </section>
        <section className="section_detalle">
          <Link to={"/detalle/" + inmueble.codigo_inmueble} className="detalle_button">Ver detalle</Link>
        </section>
      </section>
    </div>
  );
};

export default Property;