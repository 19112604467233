import * as React from "react";
import "./styles.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { LinearProgress } from "@mui/material";
import Property from "../Property";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import useWindowDimensions from "../Utils/WindowsDimensions";

const FeaturedProperties = (props) => {

  const { isMovil } = useWindowDimensions();

  return (
    <div className="FeaturedProperty">
      <div className="FeaturedPropertiesTitleCont">
        <p>Inmuebles destacados</p>
      </div>

      {props.properties == null && <LinearProgress />}

      <div className="FeaturedPropertyCarousel">
        <div className="FeaturedPropertyCarousel">
          <Swiper className="swiperPropertyFeatured"
            slidesPerView={1}
            spaceBetween={1}
            pagination={true}
            cssMode={true}
            mousewheel={true}
            keyboard={true}
            modules={[Pagination, Navigation]}
            breakpoints={{
              1024: {
                slidesPerView: 2,
                spaceBetween: 1,
              },
              1500: {
                slidesPerView: 3,
                spaceBetween: 5,
              },
              1950: {
                slidesPerView: 5,
                spaceBetween: 5,
              },
              2400: {
                slidesPerView: 6,
                spaceBetween: 5,
              },
            }}>
            <div className='swiper-wrapper'>
              {props.properties != null &&
                props.properties.map((inmueble_sim) => (
                  <SwiperSlide style={{ width: "40%" }} key={inmueble_sim.codigo_inmueble}>
                    <div className='main-featured-propertie'><Property inmueble={inmueble_sim} /></div>
                  </SwiperSlide>
                ))}
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default FeaturedProperties;
