import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./styles.css";

const Accordeon = () => {
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "transparent" }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="Accordeon"
                    TabIndicatorProps={{ style: { background: "var(--first-color)", fontSize: "18px" } }}
                >
                    <Tab Style={"text-transform: capitalize !important"} label="todos" {...a11yProps(0)} />
                    <Tab Style={"text-transform: capitalize !important"} label="propietarios" {...a11yProps(1)} />
                    <Tab Style={"text-transform: capitalize !important"} label="arrendatarios" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <div>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>
                                ¿Qué es el contrato de arrendamiento?{" "}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="accordeon-description">
                                El arrendamiento es un contrato en que las dos partes se obligan recíprocamente, la una a conceder el goce de un inmueble, y la otra a pagar por este goce, un precio determinado.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>
                                ¿Qué diferencia existe entre una aseguradora y una afianzadora?{" "}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="accordeon-description">
                                Las Inmobiliarias no son aseguradoras, nosotros administramos el inmueble. El propietario es quien toma la decisión de comprar una póliza o una fianza para garantizar su renta, y la paga cada año o mes a mes.

                                <br></br><br></br>La fianza y el seguro no son lo mismo. El seguro está regulado por el código de comercio, es supervisado por la Superintendencia de Industria y Comercio y la Superintendencia Financiera, exigiéndoles estrictos parámetros de solvencia y liquidez patrimonial.

                                <br></br><br></br>La fianza está regulada por el Código Civil y es regulada por la Superintendencia de
                                Sociedades y Superintendencia de Industria y Comercio.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>
                                ¿Qué es el P.O.T y para qué sirve?{" "}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="accordeon-description">
                                El Plan de Ordenamiento Territorial o P.O.T nos dice donde se pueden ubicar determinados sectores.  Hospitales, restaurantes y colegios. Así como nuestra vivienda, a qué altura podemos construir y cómo va a evolucionar nuestra ciudad en términos urbanos. Tienen una vigencia de 12 años.

                                <br></br><br></br>Si vas a arrendar un inmueble o comprar por inversión, para arrendarlo, debes revisar el POT, con el propósito de saber qué tipo de establecimientos pueden funcionar y debes consultar por los planos y licencias del local. Si no cuentas con estos documentos, deben ser gestionados en la curaduría del municipio.

                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>
                                ¿Qué es la compensación de parqueaderos?{" "}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="accordeon-description">
                                Es el reconocimiento que hace el propietario, poseedor, arrendatario o tenedor a favor del municipio debido a la imposibilidad de proveer al interior del predio los cupos de parqueo asociados a los usos definidos en las respectivas licencias urbanísticas en cumplimiento de lo establecido en el Plan de Ordenamiento Territorial.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>
                                ¿Qué es la licencia de construcción?{" "}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="accordeon-description">
                                Es el permiso requerido, normalmente por la administración local, para la realización de cualquier tipo de construcción.
                                <br></br><br></br>Si vas a arrendar con destinación comercial debes solicitar la licencia de construcción, ya que la misma es solicitada para la licencia de funcionamiento en algunos municipios.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>
                                ¿Qué es la licencia de funcionamiento?{" "}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="accordeon-description">
                                Es el permiso que otorga la municipalidad para que los comerciantes puedan ejercer el comercio. Dependiendo del municipio, este permiso es otorgado por la secretaría de planeación o la secretaría del interior.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <div>
                    <Accordion Style={"box-shadow: none; margin: 0 !important; padding: 0px; border-top:none !important; border-left:none !important; border-right:none !important;"}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"

                        >
                            <Typography className="accordionTitle" >Consignaciones</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1b-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Cuánto cobran por arrendar un Inmueble?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        En Rendara somos una agencia inmobiliaria que administra su propiedad. No hacemos de manera exclusiva el corretaje.
                                        <br></br><br></br>El costo mensual es del 5% más IVA. Si el propietario lo desea puede adquirir el servicio de póliza de arrendamiento y póliza de servicios públicos.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1b-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Cuándo consignan el canon de arrendamiento?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        El pago de su renta será consignado a la cuenta de ahorros proporcionada en el proceso de creación de cliente el último día hábil del mes.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1b-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Cuál es el incremento del canon por el año en curso?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        - Vivienda, IPC
                                        <br></br>- Comercio, lo pactado en el contrato de arrendamiento.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>


                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1b-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Un arrendatario se puede volver propietario por el paso del tiempo?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        No, ya que el arrendatario por el pago del canon está reconociendo que existe un dueño del bien. Aun cuando lleve en el bien arrendado más de 20 años, no es posible iniciar un proceso de posesión.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2b-content"
                                    id="panel1a-header"
                                >
                                    <Typography>¿Cuáles son los requisitos para consignar un inmueble para arriendo?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        Para consignar un inmueble en arriendo es necesario una copia de los siguientes documentos:
                                        <br></br><br></br>- Escritura Pública, hasta los linderos.
                                        <br></br>- Último recibo del Impuesto Predial.
                                        <br></br>- Cédula del propietario y/o su representante.
                                        <br></br>- RUT (destinación comercio o responsables de IVA).
                                        <br></br>- La parte normativa del régimen de Propiedad Horizontal (Artículo 8 Ley 820 de 2003) para conjuntos y edificios.
                                        <br></br>- Últimos recibos de servicios públicos (agua, luz, gas, teléfono).
                                        <br></br>- Último recibo de administración, si tiene.
                                        <br></br>- Dos duplicados de la puerta principal del inmueble y $45.000 que corresponden al costo de consignación.

                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3b-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Si mi inmueble se encuentra desocupado, la Inmobiliaria se hace cargo del pago de los servicios públicos?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        Los recibos de servicios públicos que están por llegar y corresponden al inquilino que desocupó, serán cancelados oportunamente mediante duplicados con el dinero depositado por el arrendatario para cubrir estos gastos al momento de la desocupación; si el propietario cancela dichos recibos se generará pago doble y la Inmobiliaria no reconocerá ese valor a favor del propietario.
                                        <br></br><br></br>Si el inmueble se encuentra en promoción y el propietario desea que la Inmobiliaria cancele la administración y los servicios públicos, debe autorizar directamente para efectuar el pago con cargo a rentas que tenga de otros inmuebles o dejando la respectiva provisión para tal efecto.

                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4b-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Cómo propietario de Rendara puedo tomar un inmueble en arriendo sin necesidad de presentar deudores solidarios?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        Debe presentar los documentos necesarios y hacer el proceso con la aseguradora. Es la aseguradora quien determina la necesidad o no de presentar deudores.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion Style={"box-shadow: none; margin: 0 !important; padding: 0px; border-top:none !important; border-left:none !important; border-right:none !important;"}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"

                        >
                            <Typography className="accordionTitle" >Desocupaciones</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>¿Con cuánto tiempo debo pasar una comunicación para que no se renueve el contrato de arrendamiento al arrendatario?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        <b>De vivienda.</b> Para contrato de vivienda debe enviar una notificación a la oficina con cuatro (04) meses de anterioridad a la fecha de vencimiento del contrato de arrendamiento y debe indicar el motivo por el cual necesita desocupado el predio.
                                        <br></br><br></br>Lo anterior, con el propósito que podamos hacer las debidas notificaciones en los tiempos establecidos en la Ley 820 de 2003.
                                        <br></br><br></br><b>De Comercio.</b> Para contratos de comercio debe enviar el propietario una notificación a la oficina con más de seis (06) meses de anterioridad a la fecha de vencimiento del contrato de arrendamiento y debe indicar el motivo por el cual necesita desocupado el predio.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion Style={"box-shadow: none; margin: 0 !important; padding: 0px; border-top:none !important; border-left:none !important; border-right:none !important;"}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"

                        >
                            <Typography className="accordionTitle" >Servicios Públicos</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>¿Qué requisitos se necesitan para retirar una línea telefónica por medio de la Inmobiliaria?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        Carta de autorización del propietario, autenticada, firmada y con huella digital, Certificado de Libertad y tradición no mayor a un mes de expedición, fotocopia de la Cédula de Ciudadanía del propietario, Rut.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Cuándo se desocupa un inmueble de servicio compartido quién hace la cancelación de los servicios?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        La Inmobiliaria no efectúa el pago de recibos de servicios públicos que sean compartidos, estos recibos deben ser cancelados por usted o por la persona con la cual comparte el inmueble. Para el reintegro de dinero debe enviar el recibo original cancelado; en caso de que el inquilino no haya dejado depósito para cubrir el valor de los consumos que le corresponden, se pasará a recuperación por los valores adeudados, toda vez que el seguro de servicios no cubre los servicios compartidos.

                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Si se realiza un pago doble de un servicio, quién hace el reintegro?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        El reintegro lo realiza la empresa del servicio público dentro de la misma facturación (no entregan efectivo).
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Quién realiza los pagos de servicios públicos del inactivo?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        Los realiza el departamento de servicios públicos hasta la fecha de corte y lecturas finales por medio de duplicados. (No se recogen originales en los inmuebles). Todo se hace a través de duplicados.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Qué cubre el seguro de servicios?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        El seguro de servicios cubre todos los servicios públicos del inmueble que cuenten con un medidor independiente (no planes de telefonía, internet y/o televisión), administraciones y daños de acuerdo con el valor que haya adquirido. El seguro no cubre servicios compartidos.
                                        <br></br><br></br>El orden de pago es: Servicios públicos, cuotas de administración y faltantes de inventario (no incluye pintura).
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion Style={"box-shadow: none; margin: 0 !important; padding: 0px; border-top:none !important; border-left:none !important; border-right:none !important;"}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"

                        >
                            <Typography className="accordionTitle" >Administraciones</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>¿Quiénes deben cancelar las cuotas extraordinarias?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        Es responsabilidad de los propietarios de acuerdo a las decisiones tomadas en las asambleas para un fin específico directamente con las áreas comunes del mismo.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion Style={"box-shadow: none; margin: 0 !important; padding: 0px; border-top:none !important; border-left:none !important; border-right:none !important;"}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"

                        >
                            <Typography className="accordionTitle" >PQRSD</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Cómo puedo presentar una queja?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        Se puede presentar por escrito, por medio de e-mail, página web, WhatsApp, telefónica o personalmente.
                                        <br></br><br></br>El tiempo de respuesta es de cinco (05) días hábiles, contados desde el siguiente día de su radicación.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Quién debe asumir el costo del mantenimiento de los calentadores, aires acondicionados, jacuzzis y puertas eléctricas?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        El primer mantenimiento le corresponde hacerlo al propietario antes de la entrega del inmueble y en adelante los mantenimientos anuales le corresponden al arrendatario quien está dando el uso. Si el calentador se entrega con mantenimiento correspondiente,  al momento de la desocupación el arrendatario debe entregar el calentador con el mantenimiento ejecutado.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Si el propietario entrega el inmueble pintado, la inmobiliaria se lo entrega en las mismas condiciones al momento de la desocupación?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        Al finalizar el contrato, la inmobiliaria recibe el inmueble con base en el inventario inicial de entrega al arrendatario y exige que se entregue en las mismas condiciones, salvo el uso y deterioro normal.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <div>
                    <Accordion Style={"box-shadow: none; margin: 0 !important; padding: 0px; border-top:none !important; border-left:none !important; border-right:none !important;"}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"

                        >
                            <Typography className="accordionTitle" >Cartera</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Accordion>

                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Por qué me reportaron a la Aseguradora/Afianzadora?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        Porque en la fecha en la que se presenta el reporte usted lleva más de treinta (30) días en mora. Adicionalmente, la Inmobiliaria debe cumplir con los procedimientos y fechas de reporte exigidos por las empresas afianzadoras y aseguradoras para el reporte de la mora, esto es cuando el inquilino debe dos meses de arriendo, ya que el reporte se hace el día 5 del segundo mes en mora.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>¿Por qué la Aseguradora/Afianzadora me cobra en el mes en curso si aún tengo plazo para pagar?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        Porque a la fecha del reporte a la aseguradora se adeudan dos (2) meses, ya que las fechas que se les da de plazo para cancelar en la Inmobiliaria son para quienes se encuentran al día y se paga dentro del mismo mes o el primer día de mora.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion Style={"box-shadow: none; margin: 0 !important; padding: 0px; border-top:none !important; border-left:none !important; border-right:none !important;"}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"

                        >
                            <Typography className="accordionTitle" >Desocupaciones</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Accordion>
                                <Typography className="noAccordionText">
                                    Para comercio y vivienda debe enviar una notificación con más de tres meses de anterioridad a la fecha de vencimiento del contrato de arrendamiento y debe indicar el motivo por el cual desea desocupar.
                                </Typography>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Es posible que se concedan prórrogas al contrato de arrendamiento?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        Si es posible; debe hacer una solicitud escrita indicando el tiempo que requiere de prórroga, para que de esta forma la inmobiliaria pueda hacer la respectiva consulta con el propietario y dar respuesta en atención a las instrucciones por él recibidas.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>¿Cómo hago para salir de la obligación de deudor solidario?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        Debe hablar con el cliente arrendatario para que presente una garantía real (otro deudor solidario) que cumpla con los requisitos solicitados por la afianzadora y poder así hacer el estudio para el cambio de deudor, o entregar el inmueble al vencimiento del contrato teniendo en cuenta las exigencias.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>¿Qué debo traer en el momento de la desocupación para que me hagan la liquidación del valor que debo dejar para la cancelación de servicios públicos consumidos por facturar?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        Últimas facturas originales de servicios públicos canceladas, retiros de cualquier ítem diferente a los habituales en los recibos (eje: seguros, tarjetas de crédito, olivos, Internet, Tv, etc...) paz y salvo de administración.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Si me pidieron la desocupación del inmueble puedo desocupar antes de la fecha de terminación del contrato para tomar otro inmueble con ustedes?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        Debe pasar una carta o un correo electrónico donde solicite la posibilidad de entregar el inmueble antes de la fecha de terminación del contrato y la persona encargada de desocupaciones habla con el propietario para saber si ellos autorizan recibir sin indemnización. Con respecto al nuevo inmueble que va a tomar con la Inmobiliaria, se debe presentar nuevamente la documentación actualizada, tal como lo exigen las empresas afianzadoras.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion Style={"box-shadow: none; margin: 0 !important; padding: 0px; border-top:none !important; border-left:none !important; border-right:none !important;"}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"

                        >
                            <Typography className="accordionTitle" >Contratos</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>¿Cuál es el paso a seguir después de firmado el contrato?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        Se coordina una cita para la entrega del inmueble, dejando evidencia del estado y las características del mismo dentro de un inventario escrito o un video según corresponda. Se liquida el valor que debe cancelar por concepto de canon de arriendo, administración y servicios de entrega según la fecha de ocupación del inmueble.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿En qué consiste la entrega del inmueble?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        El funcionario de la Inmobiliaria en presencia del arrendatario hace un inventario escrito detallado de las características del inmueble, además se hace un video del estado del inmueble. Se toman las lecturas de los servicios públicos y se dejan las observaciones que deben ser tenidas en cuenta por el arrendatario durante el desarrollo del contrato o al momento de la desocupación.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Cuánto tiempo dura la entrega del inmueble?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        Aproximadamente 2 horas, pero depende del tamaño del inmueble y las características del mismo, puede durar menos o más tiempo.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Puedo autorizar a otra persona para que reciba el inmueble?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        Sí, se debe coordinar previamente con el Asesor de Arriendos y se debe formalizar mediante una autorización por escrito del inquilino a la Inmobiliaria (La Auxiliar de Contratos entrega el formato para que se diligencie).
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion Style={"box-shadow: none; margin: 0 !important; padding: 0px; border-top:none !important; border-left:none !important; border-right:none !important;"}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"

                        >
                            <Typography className="accordionTitle" >Servicios Públicos</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Por qué me están cobrando agua de Junio cuando desocupé en Mayo?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        Porque se le cobra el consumo del servicio de acuerdo con la lectura tomada en el contador en el momento de la entrega del inmueble a la Inmobiliaria, independiente del mes que facture el acueducto; eso quiere decir, que dichos consumos pueden quedar dentro del mismo mes en que se desocupó el inmueble o incluso hasta dos meses después, dependiendo de la facturación del Acueducto, ya que el sistema de toma de lecturas implementado por el Acueducto es bimensual, es decir que cada dos meses se realiza la toma física de lecturas para facturación y se obtiene de la diferencia entre la lectura anterior y la lectura actual, resultado que se divide en dos para obtener el valor a cobrar por consumo en los dos meses siguientes. El cobro de cargo fijo se hace hasta la fecha de desocupación.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Hasta cuándo me van a cobrar servicios públicos?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        De acuerdo a las últimas lecturas tomadas en el momento de la desocupación del inmueble. La ley da a las empresas de servicios públicos hasta seis meses para facturar consumos u otros cargos adicionales.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Cuándo me corresponde pagar el recibo de un servicio público?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        Si dentro de las lecturas de los recibos se encuentran consumos correspondientes a las lecturas tomadas en el momento de la entrega o la desocupación del inmueble, le corresponde al arrendatario cancelarlas.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿En cuánto tiempo debo traer los recibos de servicios públicos para saber cuándo me corresponde realizar la cancelación?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        A partir de la fecha de entrega del inmueble máximo en dos (2) meses, ya que la facturación del agua viene con dos meses de atraso. Los otros servicios con treinta días (30).
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿En qué se basan para sacar la liquidación de servicios públicos pendientes por facturar?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        Se toma fecha de la desocupación para liquidar los cargos fijos que debe cancelar y para los consumos pendientes las lecturas tomadas por el Inventarista a cada uno de los contadores en la visita final; esto en base a los últimos recibos cancelados. Al liquidarse sobre recibos cancelados se obtiene un valor aproximado.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion Style={"box-shadow: none; margin: 0 !important; padding: 0px; border-top:none !important; border-left:none !important; border-right:none !important;"}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"

                        >
                            <Typography className="accordionTitle" >Administraciones</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Subió la cuota de administración y se había negociado un valor con el asesor?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        Este valor se reajustará en la proporción y en las fechas que establezca la Asamblea de Copropietarios de acuerdo al Régimen de Propiedad Horizontal.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Para qué debo traer paz y salvo de la administración si ustedes la pagan?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        Porque las administraciones pueden llegar a realizar cobros de daños ocasionados al conjunto y/o edificio en el transcurso de su estadía en el inmueble. (Eje: cobros de parqueadero adicional, daños al portón con su vehículo, multas, etc.)
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Por qué se cobra al arrendatario en la factura el concepto retroactivo y el mes, y a qué corresponde?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        Son los saldos que se originan al cambiar el valor de la cuota de administración de un año a otro y son determinados por Asambleas, se cobran a los arrendatarios o a la persona que en ese momento tiene el inmueble bajo su responsabilidad.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Quiénes deben cancelar las cuotas extraordinarias?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        Es responsabilidad de los propietarios de acuerdo a las decisiones tomadas en las asambleas para un fin específico directamente con las áreas comunes del mismo.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Cuándo los arrendatarios toman un inmueble con parqueadero comunal se cobra un valor adicional por este servicio?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        Si el parqueadero es ocupado por un solo vehículo, no se cobra siempre y cuando utilice la zona asignada para parqueo; si son dos o más vehículos se cobra por cada uno de ellos el valor establecido en el reglamento de propiedad horizontal del conjunto.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Por qué en la factura de arriendo llega el concepto como valor adicional mes de agosto o el que corresponda?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        Al momento en que el mensajero se acerca a cada conjunto y no se encuentra el recibo en portería, este genera un formato y lo radica en la portería para que el arrendatario acerque el recibo a la inmobiliaria; si no lo hace, el extemporáneo será cobrado al arrendatario en el siguiente mes.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion Style={"box-shadow: none; margin: 0 !important; padding: 0px; border-top:none !important; border-left:none !important; border-right:none !important;"}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"

                        >
                            <Typography className="accordionTitle" >Mantenimiento</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿En qué consiste la visita del inspector de mantenimiento? ¿Su visita es para realizar de una vez la reparación?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        La visita se realiza con el fin de verificar los daños existentes y determinar a quién le corresponde realizar los respectivos arreglos. El inspector no realiza arreglos.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿Qué tipo de mejoras o arreglos le corresponden al arrendatario?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        Al arrendatario le corresponden las reparaciones locativas. Se entienden por reparaciones locativas aquellas especies de deterioro que ordinariamente se producen por culpa del arrendatario o de sus dependientes, como descalabros de paredes o cercas, rotura de cristales, desgaste en rieles, llaves de agua, etc.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        ¿El propietario debe reconocer mejoras útiles que el arrendatario haga al inmueble?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordeon-description">
                                        No, el propietario o arrendador del inmueble no está obligado a reconocer las mejoras útiles, pero el arrendatario podrá llevarse los materiales sin detrimento del bien arrendado.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </TabPanel>
        </Box >
    );
};

export default Accordeon;