export class FormatUtils{

    static currency(n){
        return new Intl.NumberFormat('co-ES').format(n);
    }

    static currencyM(n){

        let v = parseInt(n/100000) / 10;

        return "$" + v + "M"
    }

}