
import React from "react";

import ReactMapboxGl, { Marker, Feature } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import market_map from "../../../assets/static/icons/marker_default.fw.png"
import "./styles.css";

import { configuration } from "../../../config";


const Map = ReactMapboxGl({
  accessToken: configuration.mapboxToken,
});


export default function PropertyMap(props) {

  let { inmueble } = props;

  return (
    <>
      { inmueble.longitud_inmueble != null && inmueble.latitud_inmueble != null &&
        <div className="pm_mapa">
          <Map
            

            style="mapbox://styles/mapbox/streets-v9"
            containerStyle={{
              height: '500px',
              width: '100%'
            }}
            center={[ inmueble.longitud_inmueble, inmueble.latitud_inmueble ]}
            zoom={[ 16.5]}

            onStyleLoad={async (map) => {
              map.doubleClickZoom.disable();
              map.dragRotate.disable();
              map.dragPan.disable();
              map.boxZoom.disable();
              map.scrollZoom.disable();
              map.touchPitch.disable();
              map.touchZoomRotate.disable();
            }}
          >
            <Marker coordinates={[inmueble.longitud_inmueble, inmueble.latitud_inmueble]} anchor="bottom">
                <img src={market_map}/>
            </Marker>
          </Map>
        </div>
      }
    </>
  );
}
