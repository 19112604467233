import React, { useState, useEffect } from "react";

import { Grid, Tooltip } from "@mui/material";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Dialog from '@mui/material/Dialog';

import { configuration } from "../../../config";

import Box from '@mui/material/Box';
import { FormatUtils } from "../Utils/FormatUtils";
import useWindowDimensions from "../Utils/WindowsDimensions";
import { FavoriteUtils } from "../Utils/FavoriteUtils";
import habitacion from "../../../assets/static/icons/icon_bed.svg"
import ducha from "../../../assets/static/icons/icon_shower.svg"
import area from "../../../assets/static/icons/icon_area.svg"
import parqueadero from "../../../assets/static/icons/icon_parking.svg"
import estrato from "../../../assets/static/icons/icon_estrato.svg"
import icon_circle_ok from "../../../assets/static/icons/icon_circle_ok.svg"
import icon_corazonoff_movil from "../../../assets/static/icons/icon_corazonoff.svg"
import icon_corazonoff_desktop from "../../../assets/static/icons/icon_corazonoff_1.svg"
import icon_corazonon from "../../../assets/static/icons/icon_corazonon.svg"
import photo_not_found from "../../../assets/static/images/image not found.png"

import "./styles.css";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default function PropertyDetail({ inmueble }) {

  let ESTADOS_FISICOS = {
    "EXC": "Excelente estado",
    "BUE": "Buen estado",
    "REG": "Regular estado",
    "MAL": "Mal estado",
    "NUV": "Para estrenar",
    "ARR": "Reformado"
  }

  // ------------------------------------------

  const { isMovil } = useWindowDimensions();
  const [isfavorito, setIsFavorito] = useState(FavoriteUtils.is(inmueble.codigo_inmueble));
  const [openArrendar, setOpenArrendar] = React.useState(false);
  const [openAgendar, setOpenAgendar] = React.useState(false);

  // ------------------------------------------

  useEffect(() => {
    FavoriteUtils.set(inmueble.codigo_inmueble, isfavorito);
  }, [isfavorito]);

  // ------------------------------------------

  function Caracteristica(params) {

    let { variable, etiqueta } = params;

    if (inmueble[variable]) {
      return <div className="pd_caracteristica"><span><img src={icon_circle_ok} />{etiqueta}</span></div>
    }

    return <></>
  }

  function carcularRangoEdad(nu) {

    try {
      let anio = new Date().getFullYear() - nu;

      if (anio <= 5) return "Antiguedad entre 0 y 5 años";
      if (anio >= 6 && anio <= 10) return "Antiguedad entre 6 y 10 años";
      if (anio >= 11 && anio <= 20) return "Antiguedad entre 11 y 20 años";
      if (anio >= 21) return "Mas de 20 años de antiguedad";
    } catch (e) {
      return "Antiguedad indefinida";
    }

  }


  // ------------------------------------------

  return (
    <div className="PropertyDetail">
      <div className="pd_header">

        <Grid container spacing={0}>
          <Grid item sm={12} md={8}>
            <h1 className="pd_h1">{inmueble.barrio_inmueble.nombre}, {inmueble.ciudad_inmueble.nombre}</h1>
            <p>{inmueble.tipo_inmueble.nombre} en arriendo</p>
          </Grid>
          <Grid item sm={12} md={4}>
            <div className="valor_canon">${FormatUtils.currency(inmueble.valor_canon_arrendamiento_numeros)}</div>
            {inmueble.valor_canon_incluye_administracion ?
              <div className="valor_admin">Administración incluida</div>
              :
              <div className="valor_admin">${FormatUtils.currency(inmueble.valor_canon_administracion_numeros)} administración</div>
            }
          </Grid>
        </Grid>

      </div>

      <div className="pd_items">

        <span><img src={habitacion} alt="habitaciones" />{inmueble.num_alcobas}</span>
        <span><img src={ducha} alt="baños" />{inmueble.num_banos}</span>
        <span><img src={area} alt="area" />{inmueble.area_construida} m&sup2;</span>
        <span><img src={parqueadero} alt="parqueadero" />{inmueble.num_parqueadero}</span>
        <span><img src={estrato} alt="estrato" />Estrato {inmueble.estrato_inmueble}</span>

      </div>


      <div className="pd_btn_favorito">
        <a onClick={() => { setIsFavorito(!isfavorito) }}>
          <Tooltip title={isfavorito ? "Quitar de favoritos" : "Marcar como favorito"} placement="top">
            <img src={isfavorito ? icon_corazonon : isMovil ? icon_corazonoff_movil : icon_corazonoff_desktop} alt="favorito" />
          </Tooltip>
        </a>
      </div>

      <div className="pd_photos">

        <Swiper
          pagination={true} navigation={true} modules={[Pagination, Navigation]}
          spaceBetween={0}
          slidesPerView={isMovil ? 1 : 2}
        >
          {inmueble.fotos != null && inmueble.fotos.map(foto => <SwiperSlide key={foto.orden}>
            <div className="pd_photo" style={{ backgroundImage: 'url("' + foto.ruta + '")' }}></div>
          </SwiperSlide>)
          }

          {inmueble.fotos == null &&
            <SwiperSlide>
              <div className="pd_photo" style={{ backgroundImage: 'url("' + photo_not_found + '")' }}></div>
            </SwiperSlide>
          }

        </Swiper>

      </div>

      <div className="pd_codigoimmueble">Código del inmueble: {inmueble.codigo_inmueble}</div>



      <div className="pd_caracteristicas">

        <h1>Características</h1>

        <Grid container spacing={0}>
          <Grid item sm={12} md={6} lg={7} className="pd_area_caracteristicas">

            {inmueble.cod_estado_fisico && <div className="pd_caracteristica"><span><img src={icon_circle_ok} />{ESTADOS_FISICOS[inmueble.cod_estado_fisico]}</span></div>}
            {inmueble.tipo_parqueadero && <div className="pd_caracteristica"><span><img src={icon_circle_ok} />Parqueadero  <span className="lower">{inmueble.tipo_parqueadero.nombre}</span></span></div>}

            {inmueble.edad_inmueble_calculada && <div className="pd_caracteristica"><span><img src={icon_circle_ok} />{carcularRangoEdad(inmueble.edad_inmueble_calculada)}</span></div>}

            <Caracteristica variable="tiene_ascensor" etiqueta="Ascensor" />
            <Caracteristica variable="tiene_caldera" etiqueta="Caldera" />
            <Caracteristica variable="tiene_cancha" etiqueta="Cancha de Juego" />
            <Caracteristica variable="tiene_circuitotv" etiqueta="Circuito de televisión" />
            <Caracteristica variable="tiene_citofono" etiqueta="Citófono" />
            <Caracteristica variable="tiene_controleq" etiqueta="Tiene controles equipos" />
            <Caracteristica variable="tiene_controlp" etiqueta="Tiene Control Porton" />
            <Caracteristica variable="tiene_cuarto_escoltas" etiqueta="Cuarto de escolta" />
            <Caracteristica variable="tiene_fachada" etiqueta="Fachada" />
            <Caracteristica variable="tiene_gym" etiqueta="Gimnasio" />
            <Caracteristica variable="tiene_parq_vis" etiqueta="Paqueadero de visitantes" />
            <Caracteristica variable="tiene_piscina" etiqueta="Piscina" />
            <Caracteristica variable="tiene_planta_elec" etiqueta="Planta eléctrica" />
            <Caracteristica variable="tiene_porteria" etiqueta="Portería" />
            <Caracteristica variable="tiene_sauna" etiqueta="Sauna" />
            <Caracteristica variable="tiene_ssocial" etiqueta="Salón social" />
            <Caracteristica variable="tiene_tacceso" etiqueta="Tarjeta de acceso" />
            <Caracteristica variable="tiene_tenis" etiqueta="Cancha de tenis" />
            <Caracteristica variable="tiene_zinfantil" etiqueta="Zona infantil" />
            <Caracteristica variable="tiene_zjuegos" etiqueta="Zona de juegos" />
            <Caracteristica variable="tiene_zverdes" etiqueta="Zonas verdes" />
            <Caracteristica variable="zona_ropas" etiqueta="Zona de ropas" />
            <Caracteristica variable="acepta_mascotas" etiqueta="Acepta mascotas" />

            {inmueble.servicios &&
              <div className="pd_caracteristica"><span><img src={icon_circle_ok} />Servicios
                {inmueble.servicios.map((servicio, i) =>
                  <span> {(inmueble.servicios.length > 1 && inmueble.servicios.length == i + 1 ? " y " : "") + servicio.nombre + (inmueble.servicios.length > 1 && inmueble.servicios.length > i + 2 ? ", " : "")}</span>)}
              </span></div>
            }

          </Grid>

          <Grid item sm={12} md={6} lg={5}>
            <p>Esta propiedad ha sido verificada por nuestros asesores comerciales y actualmente se encuentra disponible para ser ocupada.</p>

            <div className="pd_area_btn">
              <button className="btn_detail btn_ok" onClick={() => { setOpenArrendar(true) }} >¡Quiero arrendar el inmueble!</button>
              <button className="btn_detail_w btn_cita" onClick={() => { setOpenAgendar(true) }}>Agenda una cita</button>
              <button className="btn_detail_w btn_whatsapp" onClick={() => { window.open(configuration.whatsAppLink) }}>Contacta con un asesor</button>
            </div>

          </Grid>

        </Grid>


        <Dialog onClose={() => { setOpenArrendar(false) }} open={openArrendar}>
          <Box className="pd_modal">
            <h1>¡Quiero arrendar el inmueble!</h1>
            <p>Si eres usuario ingresa con tus datos en la plataforma, si no lo eres regístrate llenando nuestro formulario. </p>
            <p>Al hacerlo completarás algunos datos que necesitamos para ayudarte a arrendar el inmueble y un asesor se comunicará contigo.  </p>
            <button className="btn_inicio" onClick={() => { setOpenArrendar(false); window.open(configuration.snaptask + "carga_informacion/1a.do?q_id_formato=40581&q_id_proceso=47788&q_codigo_inmueble=" + inmueble.codigo_inmueble) }}>Inicio de sesión</button>
            <button className="btn_cerrar" onClick={() => { setOpenArrendar(false) }}></button>
          </Box>
        </Dialog>

        <Dialog onClose={() => { setOpenAgendar(false) }} open={openAgendar}>
          <Box className="pd_modal">
            <h1>Agendar cita</h1>
            <p>Si eres usuario ingresa con tus datos en la plataforma, si no lo eres regístrate llenando nuestro formulario. </p>
            <p>Al hacerlo uno de nuestros asesores se comunicará contigo y te ayudará a agendar la cita para ver el inmueble </p>
            <button className="btn_inicio" onClick={() => { setOpenAgendar(false); window.open(configuration.snaptask) }}>Inicio de sesión</button>
            <button className="btn_cerrar" onClick={() => { setOpenAgendar(false) }}></button>
          </Box>
        </Dialog>

      </div>

    </div>
  );
}
