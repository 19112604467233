import React from "react";
import "../../../src/assets/styles/FrequentlyQuestions.css"
import AccordeonQuestions from "../components/AccordeonQuestions";
import { configuration } from "../../config";

function FrequentlyQuestions() {
    return (
        <div className="faqContainer">
            <p className="faqTitle">Preguntas frecuentes</p>
            <AccordeonQuestions />
            <button className="fq-Button"
                onClick={() => {
                    window.open(
                        configuration.whatsAppLink,
                        "_blank",
                        "noopener,noreferrer"
                    );
                }}>
                Contacta un asesor
            </button>
        </div>
    );
}

export default FrequentlyQuestions;