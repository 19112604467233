import * as React from "react";
import "./styles.css";
import explora from "../../../assets/static/icons/icon_explorainmueble.svg";
import exploraNegative from "../../../assets/static/icons/icon_explorainmuebleB.svg";
import agenda from "../../../assets/static/icons/icon_agendavisita.svg";
import selecciona from "../../../assets/static/icons/icon_escogeinmueble.svg";
import aprobacion from "../../../assets/static/icons/icon_aprobacion.svg";
import firma from "../../../assets/static/icons/icon_firmacontrato.svg";
import firmaNegative from "../../../assets/static/icons/icon_firmacontratoB.svg";
import mudate from "../../../assets/static/icons/icon_mudate.svg";
import { configuration } from "../../../config.js";

const HomeCards = () => {
  return (
    <section className="homeCardsContainer">
      <div className="homeCardsTitle">
        ¡Arrendar con nosotros es así de fácil!
      </div>
      <div className="homeCardsBgRegion">
        <div className="homeCardsCards">
          <div className="homeCardsCard">
            <img src={explora} alt="" />
            <h1>Explora inmuebles</h1>
            <p>
              Encuentra el inmueble que se ajusta a tus necesidades y presupuesto
              en nuestra oferta de bienes y selecciona los inmuebles que más te
              gustan.
            </p>
          </div>
          <div className="homeCardsCard">
            <img src={agenda} alt="" />
            <h1>Agenda visita</h1>
            <p>
              Solicita una visita a través de nuestra plataforma. Un agente te
              contactará para que puedas explorar los inmuebles que te gustan.
            </p>
          </div>
          <div className="homeCardsCard">
            <img src={selecciona} alt="" />
            <h1>Escoge inmueble</h1>
            <p>
              Una vez te has decidido por un inmueble, presenta una propuesta al
              propietario.
            </p>
          </div>
          <div className="homeCardsCard">
            <img src={aprobacion} alt="" />
            <h1>Aprobación</h1>
            <p>
              <b>¡Has llegado a un acuerdo con el propietario!</b> Ahora debemos
              adelantar el proceso de estudio con la aseguradora, es 100% virtual.
            </p>
          </div>
          <div className="homeCardsCard">
            <img src={firma} alt="" />
            <h1>Firma contrato</h1>
            <p>
              Firma el contrato de arrendamiento desde la comodidad de tu hogar.{" "}
              <br /> <br /> El proceso de firma es virtual y seguro.
            </p>
          </div>
          <div className="homeCardsCard">
            <img src={mudate} alt="" />
            <h1>Múdate</h1>
            <p>
              Disfruta de tu inmueble una vez todos los trámites estén listos.
              Agenda tu cita de entrega desde nuestro calendario virtual.
            </p>
          </div>
        </div>
        <div className="homeCardsButton">
          <button
            onClick={() => {
              window.open(
                configuration.whatsAppLink,
                "_blank",
                "noopener,noreferrer"
              );
            }}
          >
            Contacta un asesor
          </button>
        </div>
      </div>
    </section>
  );
};

export default HomeCards;
