import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Routes, Route, useParams } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import HomeSearcher from "../components/HomeSearcher";
import FeaturedProperties from "../components/FeaturedProperties";
import HomeCards from "../components/HomeCards";
import WhyWithUs from "../components/WhyWithUs";
import ClientThinking from "../components/ClientThinking";
import FormHubspot from '../components/FormHubspot';

import "../../assets/styles/Home.css";

function Home() {
  const [featured, setFeatured] = useState([]);

  useEffect(() => {
    axios.get('/RendaraWebAPI/inmueble/destacados')
      .then(function (response) { setFeatured(response.data); })
      .catch(function (error) { setFeatured([]); });
  }, []);

  return (
    <div className="home">
      <HomeSearcher />
      {featured == null && <LinearProgress />}
      {featured != null && featured.length > 0 && <>
        <FeaturedProperties properties={featured} />
      </>}
      <HomeCards />
      <WhyWithUs title="¿Por qué arrendar con nosotros?" />
      <ClientThinking />
      <FormHubspot />
    </div >
  );
}

export default Home;