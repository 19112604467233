import React from "react";
import { configuration } from "../../../config.js";
import { Link } from "react-router-dom";
import "./styles.css";

const PassionateAboutNetworking = () => {
    return (
        <>
            <div className="container_passionate">
                <p className="passionate_title">Nos apasiona Crear redes. Puentes. Caminos</p>
                <p className="passionate_text">
                    que permitan importantes acuerdos inmobiliarios entre personas de forma eficiente y segura.
                </p>
            </div>
            <div className="experience_container">
                <div className="experience_title_container">
                    <p className="experience_title">Nuestra experiencia</p>
                </div>
                <div className="experience_content">
                    <div className="experience_content_group_eighty">
                        <div className="experience_since_container">
                            <p className="experience_years_eighty">80+</p>
                            <p className="anios_eighty">años</p>
                        </div>
                        <p className="experience_description_text_eighty">
                            Experiencia en el sector inmobiliario, perfeccionando la forma cómo se arrienda, mejorando los procesos para dar la mejor experiencia a todos nuestros clientes. Contamos con asesoría legal, con servicios de apoyo en todos los momentos del proceso de arrendamiento y brindamos servicios adicionales para ampliar su experiencia al momento de dejar su inmueble en nuestras manos.
                        </p>
                    </div>
                    <div className="experience_content_group_fourteen">
                        <div className="experience_since_container">
                            <p className="experience_years_fourteen">14+</p>
                            <p className="anios_fourteen">años</p>
                        </div>
                        <p className="experience_description_text_fourteen">
                            Experiencia creando negocios digitales, contamos con el conocimiento y la solidez para dar la mejor experiencia tecnológica y el respaldo a la medida.
                        </p>
                    </div>
                </div>
                <div className="button_container_passionate">
                    <Link to="/resultado">
                        <button className="filled_button">
                            Busca tu próximo inmueble
                        </button>
                    </Link>
                    <button className="border_button" onClick={() => {
                        window.open(
                            configuration.snaptask
                        );
                    }}>
                        Publica tu inmueble
                    </button>
                </div>
            </div>
        </>
    );
};

export default PassionateAboutNetworking;