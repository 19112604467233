import "./styles.css";

export default function PostYourPropertyExperience() {
  return(
    <div className="postYourPropertyExperience">
      <div className="quote">
          "Somos enlazadores de necesidades inmobiliarias, generando
          soluciones efectivas de la mano de la tecnología, con disponibilidad
          y asesoría constante a los propietarios y arrendatarios, con un
          servicio ágil, confiable y seguro".
      </div>
      <div className="title1">Nuestra experiencia inmobiliaria</div>
      <div className="ourExperience">
        <div className="number1">80+</div>
        <div className="description1">
          Experiencia en el sector inmobiliario, perfeccionando la forma
          como se arrienda, mejorando los procesos para dar la mejor
          experiencia a todos nuestros clientes.
        </div>
      </div>
      <div>
        <div className="firstPart">
          Nos apasiona Crear redes. Puentes. Caminos
        </div>
        <div className="secondPart">
          que permitan importantes acuerdos inmobiliarios entre personas de
          forma eficiente y segura
        </div>
      </div>
    </div>
  );
}