import React from "react";
import "./styles.css";
import foto_5 from "../../../assets/static/images/foto_5.png";
import foto_5_mobile from "../../../assets/static/images/be_our_partner_banner_mobile.jpg";

export default function BeOurPartnerBanner() {
  return (
    <div className="beOurPartnerBanner">
      <div>
        <img className="beOurPartnerBannerImgWeb"
          src={foto_5}
          alt="" />
        <img className="beOurPartnerBannerImgMobile"
          src={foto_5_mobile}
          alt="" />
        <div className="beOurPartnerTitle">Sé nuestro aliado</div>
        <div className="beOurPartnerDescWeb">
          Diversifica tus ingresos. Creemos redes, puentes y caminos juntos. Trabaja
          con nosotros en los procesos de entrega, toma de fotos, inventarios,
          desocupación y ejecución de obras necesarias y locativas.
        </div>
      </div>
      <div className="beOurPartnerDescMobile">
        Diversifica tus ingresos. Creemos redes, puentes y caminos juntos. Trabaja
        con nosotros en los procesos de entrega, toma de fotos, inventarios,
        desocupación y ejecución de obras necesarias y locativas.
      </div>
    </div>
  );
}
