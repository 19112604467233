import React, { useEffect } from "react";
import "./styles.css";

const FormHubspot = props => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/shell.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    region: "na1",
                    portalId: "45751789",
                    formId: "a4320cd1-fdc5-439f-abd2-528fe8c7fc7a",
                    target: '#hubspotForm'
                })
            }
        });
    }, []);

    return (
        <div className="hubspotFormContainer">
            <div id="hubspotForm"></div>
        </div>
    );
};

export default FormHubspot;