import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import PropertyDetail from "../components/PropertyDetail";
import PropertyMap from "../components/PropertyMap";
import axios from "axios";

import "../../assets/styles/Detail.css";
import BackPage from '../components/BackPage';
import SimilarProperties from '../components/SimilarProperties';

function DetailView() {

  let { cod_inmueble } = useParams();

  const [inmueble, setInmueble] = useState(null);

  let path = useLocation().pathname;

  useEffect(() => {
    setInmueble(null);
    axios.post('/RendaraWebAPI/inmueble/codigo', { codigo_inmueble: cod_inmueble })
      .then(function (response) { 

        let _inmuebles = response.data;

        _inmuebles.forEach(inm => {
          if (inm.latitud_inmueble != null && (inm.latitud_inmueble > 89 || inm.latitud_inmueble < -89)) inm.latitud_inmueble = null;
          if (inm.longitud_inmueble != null && (inm.longitud_inmueble > 179 || inm.longitud_inmueble < -179)) inm.longitud_inmueble = null;
        });

        setInmueble(_inmuebles); 
      })
      .catch(function (error) { console.log(error); setInmueble([]); });
  }, [path]);

  return (
    <div className="DetailArea">
      <BackPage label="Volver a la búsqueda inicial" dest="/resultado" />

      {inmueble == null && <LinearProgress />}

      {inmueble != null && inmueble.length > 0 && <>
        <PropertyDetail inmueble={inmueble[0]} />
        <PropertyMap inmueble={inmueble[0]} />
        <SimilarProperties inmueble={inmueble[0]} />
      </>}
    </div>
  );
}

export default DetailView;
