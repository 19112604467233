import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import './styles.css';
import star from '../../../assets/static/icons/star.svg';

import { Pagination } from "swiper";

const ClientThinking = () => {

    return (

        <div className='container_client_thinking'>
            <div className='title_client_thinking'>
                <p>¿Qué piensan nuestros arrendatarios?</p>
            </div>
            <div className='container_card_client_thinking'>
                <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
                    <SwiperSlide>
                        <div className='card_client_thinking'>
                            <p className='card_client_thinking_name'>Fernando Arias</p>
                            <div className='card_client_thinking_stars'>
                                <img src={star} alt="" />
                                <img src={star} alt="" />
                                <img src={star} alt="" />
                                <img src={star} alt="" />
                                <img src={star} alt="" />
                            </div>
                            <p className='card_client_thinking_text'>“Nos encantó <b>la amabilidad y responsabilidad</b>. Siempre estuvieron atentos a nuestras consultas, su servicio fue de primer nivel”</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='card_client_thinking'>
                            <p className='card_client_thinking_name'>Daniela Díaz</p>
                            <div className='card_client_thinking_stars'>
                                <img src={star} alt="" />
                                <img src={star} alt="" />
                                <img src={star} alt="" />
                                <img src={star} alt="" />
                                <img src={star} alt="" />
                            </div>
                            <p className='card_client_thinking_text'>“Fue <b>rápido</b> encontrar el inmueble y mucho más rápido tomarlo en arriendo. Fue <b>sencilla</b> la comunicación.”</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='card_client_thinking'>
                            <p className='card_client_thinking_name'>Ciliana Capera</p>
                            <div className='card_client_thinking_stars'>
                                <img src={star} alt="" />
                                <img src={star} alt="" />
                                <img src={star} alt="" />
                                <img src={star} alt="" />
                                <img src={star} alt="" />
                            </div>
                            <p className='card_client_thinking_text'>“Desde el primer momento en que me contactaron estuvieron pendientes para concretar de forma <b>efectiva</b> el arriendo del apartamento y llevaron a cabo la <b>tramitación de forma segura</b>”.</p>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
            <div className='container_card_client_thinking_web'>
                <div className='card_client_thinking'>
                    <p className='card_client_thinking_name'>Fernando Arias</p>
                    <div className='card_client_thinking_stars'>
                        <img src={star} alt="" />
                        <img src={star} alt="" />
                        <img src={star} alt="" />
                        <img src={star} alt="" />
                        <img src={star} alt="" />
                    </div>
                    <p className='card_client_thinking_text'>“Nos encantó <b>la amabilidad y responsabilidad</b>. Siempre estuvieron atentos a nuestras consultas, su servicio fue de primer nivel”</p>
                </div>
                <div className='card_client_thinking'>
                    <p className='card_client_thinking_name'>Daniela Díaz</p>
                    <div className='card_client_thinking_stars'>
                        <img src={star} alt="" />
                        <img src={star} alt="" />
                        <img src={star} alt="" />
                        <img src={star} alt="" />
                        <img src={star} alt="" />
                    </div>
                    <p className='card_client_thinking_text'>“Fue <b>rápido</b> encontrar el inmueble y mucho más rápido tomarlo en arriendo. Fue <b>sencilla</b> la comunicación.”</p>
                </div>
                <div className='card_client_thinking'>
                    <p className='card_client_thinking_name'>Ciliana Capera</p>
                    <div className='card_client_thinking_stars'>
                        <img src={star} alt="" />
                        <img src={star} alt="" />
                        <img src={star} alt="" />
                        <img src={star} alt="" />
                        <img src={star} alt="" />
                    </div>
                    <p className='card_client_thinking_text'>“Desde el primer momento en que me contactaron estuvieron pendientes para concretar de forma <b>efectiva</b> el arriendo del apartamento y llevaron a cabo la <b>tramitación de forma segura</b>”.</p>
                </div>
            </div>
            <button className="button_client_thinking">
                <Link to={"/resultado"} className="button_client_thinking_text">Buscar inmuebles</Link>
            </button>
        </div>
    );
}

export default ClientThinking;