import React from 'react';
import './styles.css';
import ImageBannerWeb from '../../../assets/static/images/About Us Web.jpg'
import ImageBannerMobile from '../../../assets/static/images/About Us Mobile.jpg'

const AboutUsBanner = () => {
  return (
    <div>
      <div className='ImageBannerMobileContainer'>
        <img className='ImageBannerMobile' src={ImageBannerMobile} alt="" />
        <p className='title_banner_mobile'>
          Acerca de Rendara
        </p>
        <p className='text_banner_mobile'>
          Somos enlazadores de necesidades inmobiliarias, generando soluciones efectivas de la mano de la tecnología, con disponibilidad y asesoría constante a los propietarios y arrendatarios, con un servicio ágil, confiable y seguro.
        </p>
      </div>
      <div className='ImageBannerWebContainer'>
        <img className='ImageBannerWeb' src={ImageBannerWeb} alt="" />
        <p className='title_banner_web'>
          Acerca de Rendara
        </p>
        <p className='text_banner_web'>
          Somos enlazadores de necesidades inmobiliarias, generando soluciones efectivas de la mano de la tecnología, con disponibilidad y asesoría constante a los propietarios y arrendatarios, con un servicio ágil, confiable y seguro.
        </p>
      </div>
    </div>
  );
}

export default AboutUsBanner;
