import * as React from "react";
import "./styles.css";
import why_us_p1 from "../../../assets/static/images/why_us_p1.png";
import why_us_p2 from "../../../assets/static/images/why_us_p2.png";
import why_us_p3 from "../../../assets/static/images/why_us_p3.png";
import why_us_p4 from "../../../assets/static/images/why_us_p4.png";
import lamp from "../../../assets/static/icons/lamp.svg";
import shower from "../../../assets/static/icons/shower.svg";
import chair from "../../../assets/static/icons/office_chair.svg";
import hanger from "../../../assets/static/icons/hanger.svg";

const WhyWithUs = (props) => {
  return (
    <div className="whyWithUsContainer">
      <div className="whyWithUsTitleCont">
        <p>{props.title}</p>
      </div>
      <div className="whyWithUsSubCont1">
        <div className="whyWithUsImgCont">
          <div className="whyWithUsImg"><img src={why_us_p1} alt="" /></div>
          <div className="whyWithUsSubContBg1"><img src={lamp} alt="" /></div>
        </div>
        <div className="whyWithUsTextCont">
          <p className="whyWithUsSubContTitle whyWithUsTitleStart">
            Fácil, rápido y sin complicaciones
          </p>
          <p className="whyWithUsSubContText whyWithUsTextStart">
            Procesos ágiles, gestiona tus procesos de manera rápida y sencilla.
            Cuenta con la trazabilidad de todas tus solicitudes, respuesta
            inmediata y varios servicios más.
          </p>
        </div>
      </div>
      <div className="whyWithUsSubCont2">
        <div className="whyWithUsImgCont">
          <div className="whyWithUsImg"><img src={why_us_p2} alt="" /></div>
        </div>
        <div className="whyWithUsTextCont">
          <p className="whyWithUsSubContTitle whyWithUsTitleEnd">
            Respaldo y experiencia
          </p>
          <p className="whyWithUsSubContText whyWithUsTextEnd">
            Contamos con más de 80 años de experiencia en el sector inmobiliario
            y 14 años en el sector tecnológico, de la mano de nuestros
            fundadores te brindaremos el mejor servicio.
          </p>
          <div className="whyWithUsSubContBg2"><img src={shower} alt="" /></div>
        </div>
      </div>
      <div className="whyWithUsSubCont3">
        <div className="whyWithUsImgCont">
          <div className="whyWithUsImg"><img src={why_us_p3} alt="" /></div>
          <div className="whyWithUsSubContBg3"><img src={chair} alt="" /></div>
        </div>
        <div className="whyWithUsTextCont">
          <p className="whyWithUsSubContTitle whyWithUsTitleStart">
            Siempre estamos contigo
          </p>
          <p className="whyWithUsSubContText whyWithUsTextStart">
            Contamos con un equipo altamente capacitado que te asesorará en el
            menor tiempo posible todas tus dudas e inquietudes, las 24 horas del
            día, los 7 días de la semana.
          </p>
        </div>
      </div>
      <div className="whyWithUsSubCont4">
        <div className="whyWithUsImgCont">
          <div className="whyWithUsImg"><img src={why_us_p4} alt="" /></div>
        </div>
        <div className="whyWithUsTextCont">
          <p className="whyWithUsSubContTitle whyWithUsTitleEnd">
            Experiencia del cliente
          </p>
          <p className="whyWithUsSubContText whyWithUsTextEnd">
            Nuestras soluciones se basan en solicitudes reales del sector
            inmobiliario, las cuales nos han dado la experiencia para crear
            soluciones acordes a los usuarios y potenciadas con el diseño de
            experiencias digitales para brindar el mejor servicio posible.
          </p>
          <div className="whyWithUsSubContBg4"><img src={hanger} alt="" /></div>
        </div>
      </div>
    </div>
  );
};

export default WhyWithUs;
