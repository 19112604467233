import React from "react";
import "../../assets/styles/Post.css";
import PostYourPropertyBanner from "../components/PostYourPropertyBanner";
import PostYourPropertyCards from "../components/PostYourPropertyCards";
import PostYourPropertyExperience from "../components/PostYourPropertyExperience";
import WhyWithUs from "../components/WhyWithUs";
import LeseeThinking from "../components/LesseeThinking";

function Post() {
  return (
    <div className="post">
      <PostYourPropertyBanner />
      <PostYourPropertyCards />
      <PostYourPropertyExperience />
      <WhyWithUs title="¿Por qué publicar su inmueble con nosotros?" />
      <LeseeThinking />
    </div>
  );
}

export default Post;
