import React, { useState, useEffect } from 'react';
import axios from "axios";
import Property from '../../components/Property';
import { Grid, LinearProgress } from "@mui/material";
import useWindowDimensions from "../Utils/WindowsDimensions";

import "./styles.css";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default function SimilarProperties(props) {

  let { inmueble } = props;

  const [similares, setSimilares] = useState(null);

  const { height, width, isMovil } = useWindowDimensions();

  useEffect(() => {
    axios.post('/RendaraWebAPI/inmueble/similares', { codigo_inmueble: inmueble.codigo_inmueble })
      .then(function (response) { setSimilares(response.data); })
      .catch(function (error) { setSimilares([]); });
  }, []);

  return (
    <>
      {similares == null && <LinearProgress />}
      
      {similares != null && similares.length > 0 &&
        <div className="SimilarProperty">

          <h1 className='sp_h1'>Inmuebles similares</h1>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            rowSpacing={0}
            columnSpacing={8}
          >
            {similares != null && similares.map(inmueble_sim =>
              <Grid item key={inmueble_sim.codigo_inmueble}>
                <div className='propiedad'><Property inmueble={inmueble_sim} /></div>
              </Grid>
            )}

          </Grid>

        </div>
      }
    </>
  );
}
