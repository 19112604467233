import {
  Box,
  Button,
  FormHelperText,
  TextField
} from "@mui/material";
import React, { useEffect } from "react";
import "./styles.css";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

function handleInput(event, func) {
  let value = event.target.value;
  value = value.replace(/\D+/, "");

  if (value === "") {
    func("")
    return;
  }

  func(parseInt(value));
  return;
}

function checkRange(min, max) {
  if (min === "" || max === "") {
    return false;
  }

  if (min > max) {
    return true;
  }

  return false;
}

function encodeAntiquity(values) {
  if (values === undefined || values === null) {
    return null;
  }

  let antiquities = [];
  values.forEach((value) => {
    let antiquity = {
      desde: parseInt(value.split("-")[0]),
      hasta: parseInt(value.split("-")[1]),
    };
    antiquities.push(antiquity);
  });

  return antiquities;
}

function decodeAntiquity(values) {
  if (values === undefined || values === null) {
    return null;
  }

  let antiquities = [];
  values.forEach((value) => {
    let antiquity = `${value.desde}-${value.hasta}`;
    antiquities.push(antiquity);
  });

  return antiquities;
}

export default function SearchFilter({ filterData, setfilterData }) {

  const [estratos, setEstratos] = React.useState([
    { id: "1", nombre: "UNO" },
    { id: "2", nombre: "DOS" },
    { id: "3", nombre: "TRES" },
    { id: "4", nombre: "CUATRO" },
    { id: "5", nombre: "CINCO" },
    { id: "6", nombre: "SEIS" },
    { id: "7", nombre: "COMERCIAL" },
    { id: "8", nombre: "SIN ASIGNAR" },
  ]);

  const [estados_fisicos, setEstados_fisicos] = React.useState([
    { id: "EXC", nombre: "EXCELENTE" },
    { id: "BUE", nombre: "BUENO" },
    { id: "REG", nombre: "REGULAR" },
    { id: "MAL", nombre: "MALO" },
    { id: "NUV", nombre: "ESTRENAR" },
    { id: "ARR", nombre: "REFORMADO" },
  ]);

  const [tipos_parqueadero, setTipos_parqueadero] = React.useState([
    { id: "1", nombre: "DOBLE" },
    { id: "2", nombre: "SENCILLO" },
    { id: "DPA", nombre: "DOS PARQUEADEROS" },
    { id: "3", nombre: "CUBIERTO" },
    { id: "4", nombre: "DESCUBIERTO" },
    { id: "5", nombre: "COMUNAL" },
    { id: "6", nombre: "CON SERVIDUMBRE" },
  ]);

  const [servicios_publicos, setServicios_publicos] = React.useState([
    { id: "AGU", nombre: "AGUA" },
    { id: "LUZ", nombre: "LUZ" },
    { id: "GAS", nombre: "GAS" },
  ]);

  const [numOfBathsButtons, setNumOfBathsButtons] = React.useState(() => []);
  const [stratumsButtons, setStratumsButtons] = React.useState(() => []);
  const [includedAdminButtons, setIncludedAdminButtons] = React.useState(null);
  const [minAdminPriceInput, setMinAdminPriceInput] = React.useState("");
  const [maxAdminPriceInput, setMaxAdminPriceInput] = React.useState("");
  const [minAreaInput, setMinAreaInput] = React.useState("");
  const [maxAreaInput, setMaxAreaInput] = React.useState("");
  const [physicalStateButtons, setPhysicalStateButtons] = React.useState(
    () => []
  );
  const [antiquityButtons, setAntiquityButtons] = React.useState(() => []);
  const [numOfParkButtons, setNumOfParkButtons] = React.useState(() => []);
  const [typeOfParkButtons, setTypeOfParkButtons] = React.useState(() => []);
  const [publicServicesButtons, setPublicServicesButtons] = React.useState(
    () => []
  );
  const [petsButtons, setPetsButtons] = React.useState(null);

  useEffect(() => {
    setNumOfBathsButtons(filterData.num_banos);
    setStratumsButtons(filterData.estratos);
    setIncludedAdminButtons(filterData.administracion_incluida);
    setMinAdminPriceInput(filterData.valor_canon_administracion_desde);
    setMaxAdminPriceInput(filterData.valor_canon_administracion_hasta);
    setMinAreaInput(filterData.area_construida_desde);
    setMaxAreaInput(filterData.area_construida_hasta);
    setPhysicalStateButtons(filterData.estado_fisico);
    setAntiquityButtons(decodeAntiquity(filterData.antiguedad));
    setNumOfParkButtons(filterData.num_parqueaderos);
    setTypeOfParkButtons(filterData.tipos_parqueadero);
    setPublicServicesButtons(filterData.servicios);
    setPetsButtons(filterData.acepta_mascotas);
  }, []);

  // -----------------------------------------------------------

  const [disableAcceptButton, setDisableAcceptButton] = React.useState(true);

  useEffect(() => {
    if (checkRange(minAdminPriceInput, maxAdminPriceInput) ||
      checkRange(minAreaInput, maxAreaInput)) {
      setDisableAcceptButton(true);
    } else {
      setDisableAcceptButton(false);
    }
  }, [minAdminPriceInput, maxAdminPriceInput, minAreaInput, maxAreaInput]);

  function cancelar() {
    setfilterData(filterData);
  }

  function borrar() {
    setNumOfBathsButtons([]);
    setStratumsButtons([]);
    setIncludedAdminButtons(null);
    setMinAdminPriceInput("");
    setMaxAdminPriceInput("");
    setMinAreaInput("");
    setMaxAreaInput("");
    setPhysicalStateButtons([]);
    setAntiquityButtons([]);
    setNumOfParkButtons([]);
    setTypeOfParkButtons([]);
    setPublicServicesButtons([]);
    setPetsButtons(null);
  }

  function aceptar() {
    let filtro = {
      num_banos: numOfBathsButtons,
      estratos: stratumsButtons,
      administracion_incluida: includedAdminButtons,
      valor_canon_administracion_desde: minAdminPriceInput,
      valor_canon_administracion_hasta: maxAdminPriceInput,
      area_construida_desde: minAreaInput,
      area_construida_hasta: maxAreaInput,
      estado_fisico: physicalStateButtons,
      antiguedad: encodeAntiquity(antiquityButtons),
      num_parqueaderos: numOfParkButtons,
      tipos_parqueadero: typeOfParkButtons,
      servicios: publicServicesButtons,
      acepta_mascotas: petsButtons,
    };

    setfilterData(filtro);
  }

  return (
    <Box className="SearchFilter">
      <div className="filterTopContainer">
        <p className="filtersTitle">
          Aplica más filtros para encontrar tu inmueble ideal
        </p>
        <button onClick={() => cancelar()}>x</button>
      </div>
      <hr />
      <div className="filtersContainer">
        <div className="filtersText">Número de baños</div>
        <ToggleButtonGroup
          className="filterButtonGroup"
          size="220px"
          value={numOfBathsButtons}
          onChange={(e, s) => {
            setNumOfBathsButtons(s);
          }}
          aria-label="text alignment"
        >
          <ToggleButton value={1} className="numberFilter">
            1
          </ToggleButton>
          <ToggleButton value={2} className="numberFilter">
            2
          </ToggleButton>
          <ToggleButton value={3} className="numberFilter">
            3
          </ToggleButton>
          <ToggleButton value={4} className="numberFilter">
            4
          </ToggleButton>
          <ToggleButton value={5} className="numberFilter">
            +5
          </ToggleButton>
        </ToggleButtonGroup>
        <div className="filtersText">Estrato</div>
        <ToggleButtonGroup
          className="filterButtonGroup"
          size="220px"
          value={stratumsButtons}
          onChange={(e, s) => {
            setStratumsButtons(s);
          }}
          aria-label="text alignment"
        >
          {estratos.map((estrato) => (
            <ToggleButton value={estrato.id} className="textFilter">
              {estrato.nombre}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <div className="filtersText">Administración incluida</div>
        <ToggleButtonGroup
          className="filterButtonGroup"
          size="220px"
          value={includedAdminButtons}
          exclusive
          onChange={(e, s) => {
            setIncludedAdminButtons(s);
          }}
          aria-label="text alignment"
        >
          <ToggleButton value={true} className="textFilter">
            SI
          </ToggleButton>
          <ToggleButton value={false} className="textFilter">
            NO
          </ToggleButton>
        </ToggleButtonGroup>
        <div className="filtersText">Valor administración</div>
        <div className="filtersInputsContainer1">
          <div className="filtersInputs">
            <div>
              <p className="filterInputsLabel">Mínimo</p>
              <TextField
                error={checkRange(minAdminPriceInput, maxAdminPriceInput)}
                value={minAdminPriceInput}
                onChange={(e) => {
                  handleInput(e, setMinAdminPriceInput);
                }}
              ></TextField>
            </div>
            <div>
              <p className="filterInputsLabel">Máximo</p>
              <TextField
                error={checkRange(minAdminPriceInput, maxAdminPriceInput)}
                value={maxAdminPriceInput}
                onChange={(e) => {
                  handleInput(e, setMaxAdminPriceInput);
                }}
              ></TextField>
            </div>
            {checkRange(minAdminPriceInput, maxAdminPriceInput) ? (
              <FormHelperText className="helperTextError" error>
                ¡El área máxima no puede ser menor que la mínima!
              </FormHelperText>
            ) : (
              <></>
            )}
          </div>
        </div>
        {checkRange(minAdminPriceInput, maxAdminPriceInput) ? (
          <FormHelperText className="helperTextErrorMobile" error>
            ¡El área máxima no puede ser menor que la mínima!
          </FormHelperText>
        ) : (
          <></>
        )}
        <div className="filtersText">Mt2 de área construida</div>
        <div className="filtersInputsContainer2">
          <div className="filtersInputs">
            <div>
              <p className="filterInputsLabel">Mínimo</p>
              <TextField
                error={checkRange(minAreaInput, maxAreaInput)}
                value={minAreaInput}
                onChange={(e) => {
                  handleInput(e, setMinAreaInput);
                }}
              ></TextField>
            </div>
            <div>
              <p className="filterInputsLabel">Máximo</p>
              <TextField
                error={checkRange(minAreaInput, maxAreaInput)}
                value={maxAreaInput}
                onChange={(e) => {
                  handleInput(e, setMaxAreaInput);
                }}
              ></TextField>
            </div>
            {checkRange(minAreaInput, maxAreaInput) ? (
              <FormHelperText className="helperTextError" error>
                ¡El área máxima no puede ser menor que la mínima!
              </FormHelperText>
            ) : (
              <></>
            )}
          </div>
        </div>
        {checkRange(minAreaInput, maxAreaInput) ? (
          <FormHelperText className="helperTextErrorMobile" error>
            ¡El área máxima no puede ser menor que la mínima!
          </FormHelperText>
        ) : (
          <></>
        )}
        <div className="filtersText">Estado inmueble</div>
        <ToggleButtonGroup
          className="filterButtonGroup"
          size="220px"
          value={physicalStateButtons}
          onChange={(e, s) => {
            setPhysicalStateButtons(s);
          }}
          aria-label="text alignment"
        >
          {estados_fisicos.map((estadoFisico) => (
            <ToggleButton value={estadoFisico.id} className="textFilter">
              {estadoFisico.nombre}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <div className="filtersText">Antigüedad</div>
        <ToggleButtonGroup
          className="filterButtonGroup"
          size="220px"
          value={antiquityButtons}
          onChange={(e, s) => {
            setAntiquityButtons(s);
          }}
          aria-label="text alignment"
        >
          <ToggleButton value="0-5" className="textFilter">
            0 a 5 años
          </ToggleButton>
          <ToggleButton value="6-10" className="textFilter">
            6 a 10 años
          </ToggleButton>
          <ToggleButton value="11-20" className="textFilter">
            11 a 20 años
          </ToggleButton>
          <ToggleButton value="20-1000" className="textFilter">
            + de 20 años
          </ToggleButton>
        </ToggleButtonGroup>
        <div className="filtersText">Número de parqueaderos</div>
        <ToggleButtonGroup
          className="filterButtonGroup"
          size="220px"
          value={numOfParkButtons}
          onChange={(e, s) => {
            setNumOfParkButtons(s);
          }}
          aria-label="text alignment"
        >
          <ToggleButton value="1" className="numberFilter">
            1
          </ToggleButton>
          <ToggleButton value="2" className="numberFilter">
            2
          </ToggleButton>
          <ToggleButton value="3" className="numberFilter">
            +3
          </ToggleButton>
        </ToggleButtonGroup>
        <div className="filtersText">Tipo de parqueadero</div>
        <ToggleButtonGroup
          className="filterButtonGroup"
          size="220px"
          value={typeOfParkButtons}
          onChange={(e, s) => {
            setTypeOfParkButtons(s);
          }}
          aria-label="text alignment"
        >
          {tipos_parqueadero.map((tipoParqueadero) => (
            <ToggleButton value={tipoParqueadero.id} className="textFilter">
              {tipoParqueadero.nombre}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <div className="filtersText">Servicios públicos</div>
        <ToggleButtonGroup
          className="filterButtonGroup"
          size="220px"
          value={publicServicesButtons}
          onChange={(e, s) => {
            setPublicServicesButtons(s);
          }}
          aria-label="text alignment"
        >
          {servicios_publicos.map((servicioPublico) => (
            <ToggleButton value={servicioPublico.id} className="textFilter">
              {servicioPublico.nombre}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <div className="filtersText">Acepta mascotas</div>
        <ToggleButtonGroup
          className="filterButtonGroup"
          size="220px"
          value={petsButtons}
          exclusive
          onChange={(e, s) => {
            setPetsButtons(s);
          }}
          aria-label="text alignment"
        >
          <ToggleButton value={true} className="textFilter">
            SI
          </ToggleButton>
          <ToggleButton value={false} className="textFilter">
            NO
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <hr />
      <div className="filtersButtonContainer">
        <Button className="filterCancelButton"
          onClick={() => cancelar()}>Cancelar</Button>
        <Button className="filterEraseButton"
          onClick={() => borrar()}>Borrar</Button>
        <Button className="filterAgreeButton"
          disabled={disableAcceptButton}
          onClick={() => aceptar()}>
          Aceptar
        </Button>
      </div>
    </Box>
  );
}
