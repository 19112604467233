import React from "react";
import "./styles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import star from "../../../assets/static/icons/star.svg";
import { Pagination } from "swiper";
import { configuration } from "../../../config";

const LeseeThinking = () => {
  return (
    <div className="container_lessee_thinking">
      <div className="title_lessee_thinking">
        <p>¿Qué piensan nuestros arrendadores?</p>
      </div>
      <div className="container_card_lessee_thinking">
        <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
          <SwiperSlide>
            <div className="card_lessee_thinking">
              <p className="card_lessee_thinking_name">Andrés Barrantes</p>
              <div className="card_lessee_thinking_stars">
                <img src={star} alt="" />
                <img src={star} alt="" />
                <img src={star} alt="" />
                <img src={star} alt="" />
                <img src={star} alt="" />
              </div>
              <p className="card_lessee_thinking_text">
                “Gran <b>respaldo</b>, tuvimos nuestro apartamento poco tiempo
                desocupado, y siempre tuvimos <b>información</b> de lo que estaba pasando.”
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="card_lessee_thinking">
              <p className="card_lessee_thinking_name">Luis Ortega</p>
              <div className="card_lessee_thinking_stars">
                <img src={star} alt="" />
                <img src={star} alt="" />
                <img src={star} alt="" />
                <img src={star} alt="" />
                <img src={star} alt="" />
              </div>
              <p className="card_lessee_thinking_text">
                “La forma de pago nos sirvió mucho a nosotros, nos encantó <b>el respaldo</b>,
                gente siempre <b>atenta</b> a nuestras necesidades y aunque fuera una inmobiliaria
                digital sentimos que fueron muy <b>humanos</b>.”
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="card_lessee_thinking">
              <p className="card_lessee_thinking_name">Fernando León</p>
              <div className="card_lessee_thinking_stars">
                <img src={star} alt="" />
                <img src={star} alt="" />
                <img src={star} alt="" />
                <img src={star} alt="" />
                <img src={star} alt="" />
              </div>
              <p className="card_lessee_thinking_text">
              “Tienen una <b>plataforma</b> que te permite ver toda la información que necesitas,
              así no te comuniques, solo entras y ves cómo está tu
              inmueble, <b>fácilmente</b>, <b>rápidamente</b>, desde cualquier lugar.”
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="container_card_lessee_thinking_web">
        <div className="card_lessee_thinking">
          <p className="card_lessee_thinking_name">Andrés Barrantes</p>
          <div className="card_lessee_thinking_stars">
            <img src={star} alt="" />
            <img src={star} alt="" />
            <img src={star} alt="" />
            <img src={star} alt="" />
            <img src={star} alt="" />
          </div>
          <p className="card_lessee_thinking_text">
            “Gran <b>respaldo</b>, tuvimos nuestro apartamento poco tiempo
            desocupado, y siempre tuvimos <b>información</b> de lo que estaba pasando.”
          </p>
        </div>
        <div className="card_lessee_thinking">
          <p className="card_lessee_thinking_name">Luis Ortega</p>
          <div className="card_lessee_thinking_stars">
            <img src={star} alt="" />
            <img src={star} alt="" />
            <img src={star} alt="" />
            <img src={star} alt="" />
            <img src={star} alt="" />
          </div>
          <p className="card_lessee_thinking_text">
            “La forma de pago nos sirvió mucho a nosotros, nos encantó <b>el respaldo</b>,
            gente siempre <b>atenta</b> a nuestras necesidades y aunque fuera una inmobiliaria
            digital sentimos que fueron muy <b>humanos</b>.”
          </p>
        </div>
        <div className="card_lessee_thinking">
          <p className="card_lessee_thinking_name">Fernando León</p>
          <div className="card_lessee_thinking_stars">
            <img src={star} alt="" />
            <img src={star} alt="" />
            <img src={star} alt="" />
            <img src={star} alt="" />
            <img src={star} alt="" />
          </div>
          <p className="card_lessee_thinking_text">
            “Tienen una <b>plataforma</b> que te permite ver toda la información que necesitas,
            así no te comuniques, solo entras y ves cómo está tu
            inmueble, <b>fácilmente</b>, <b>rápidamente</b>, desde cualquier lugar.”
          </p>
        </div>
      </div>
      <button className="button_lessee_thinking"
        onClick={() => {window.open(configuration.snaptask)}}>
        Publicar inmuebles
      </button>
    </div>
  );
};

export default LeseeThinking;
