import { useEffect, useState } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  const isMovil = width < 900;
  return {
    width,
    height,
    isMovil
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {

      let dim = getWindowDimensions();

      if(dim.width != windowDimensions.width){
        console.log("cambio ", dim, windowDimensions )
        setWindowDimensions(dim);
      }

    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}