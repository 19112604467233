import * as React from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import rendara_logo from "../../../assets/static/icons/logo_horizontal.png";
import { configuration } from "../../../config.js";
import "./styles.css";

const Header = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <a href="/" className="button-no-hover">
            <img className="rendara-logo-header" src={rendara_logo} alt="Logo Rendara" />
          </a>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              onClick={handleOpenNavMenu}
              color="black"
              sx={{
                display: { md: "flex", lg: "none" },
              }}
            >
              <MenuIcon />
            </IconButton>

            <Menu
              id="menu-appbar"
              className="MenuBase"
              anchorEl={anchorElNav}
              anchorOrigin={{
                display: "flex",
                vertical: "bottom",
                horizontal: "center",
                top: "56px",
                right: "0px",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ width: "100%", display: { md: "flex", lg: "none" } }}
            >
              <MenuItem
                className="MenuItemHeader"
                onClick={handleCloseNavMenu}
                sx={{
                  display: { lg: "none" },
                }}
              >
                <button className="btn_close" onClick={handleCloseNavMenu}><span>Cerrar</span><CloseIcon /></button>
                <Link to="/">Encuentra tu nuevo inmueble</Link>
                <Link to="/publicar-inmueble">Publica tu inmueble</Link>
                <Link to="/quienes-somos">Quienes somos</Link>
                <Link to="/preguntas-frecuentes">Preguntas frecuentes</Link>
                <Link to="/favoritos">Favoritos</Link>

                <a href={configuration.snaptask} target="_blank" rel="noreferrer" className="btn_inicio_sesion">
                  Inicio sesión
                </a>
                
              </MenuItem>
             
            </Menu>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", lg: "flex", justifyContent: "flex-end" },
            }}
          >
            <Link to="/">Encuentra tu nuevo inmueble</Link>
            <Link to="/publicar-inmueble">Publica tu inmueble</Link>
            <Link to="/quienes-somos">Quienes somos</Link>
            <Link to="/preguntas-frecuentes">Preguntas frecuentes</Link>
            <Link to="/favoritos">Favoritos</Link>
            <div className="button_container_header">
              <a href={configuration.snaptask} target="_blank" rel="noreferrer" >
                Inicio sesión
              </a>
            </div>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;