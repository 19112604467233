import React from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from "react-router-dom";
import "./styles.css";

export default function BackPage(props) {

  let { dest, label } = props;

  return (
    <div className="BackPage">
        <Link to={dest} className="btn">
            <ArrowBackIosIcon sx={{ fontSize: 22 }}/>
            <p>{label}</p>
        </Link>
    </div>
  );
}
