export class FavoriteUtils {

    static get() {

        let favoritos = [];
        let s_favoritos = localStorage.getItem("favorites");

        if (s_favoritos != null) {
            favoritos = JSON.parse(s_favoritos);
        }

        return favoritos;
    }

    static set(n, b) {
        return b ? this.add(n) : this.del(n);
    }


    static is(n) {
        let favoritos = this.get();
        return favoritos.includes(n);
    }

    static add(n) {

        let favoritos = this.get();

        if (this.is(n)) {
            return favoritos;
        }

        favoritos.push(n);
        localStorage.setItem("favorites", JSON.stringify(favoritos));

        return favoritos;
    }

    static del(n) {
        let favoritos = this.get();

        const index = favoritos.indexOf(n);

        if (index >= 0) {
            favoritos.splice(index, 1);
        }

        localStorage.setItem("favorites", JSON.stringify(favoritos));

        return favoritos;
    }

}