import * as React from "react";
import "./styles.css";
import registrate from "../../../assets/static/icons/icon_registrate.svg";
import contactamos from "../../../assets/static/icons/icon_tecontactamos.svg";
import visitamos from "../../../assets/static/icons/icon_visitamosinmueble.svg";
import promocionamos from "../../../assets/static/icons/icon_promocionamosinm.svg";
import negociacion from "../../../assets/static/icons/icon_negociacion.svg";
import reciberenta from "../../../assets/static/icons/icon_recibeturenta.svg";
import { configuration } from "../../../config.js";

export default function PostYourPropertyCards() {
  return (
    <section className="postCardsContainer">
      <div className="postCardsTitle">
        Deja tu inmueble en nuestras manos
      </div>
      <div className="postCardsBgRegion">
        <div className="postCardsCards">
          <div className="postCardsCard">
            <img src={registrate} alt="" />
            <h1>Regístrate</h1>
            <p>
              Publica gratis el inmueble y te ayudamos a contactar con un
              arrendatario seguro y confiable. <br/> <br/>
              Diligencia la información básica de tu inmueble y adjunta los
              documentos requeridos.
            </p>
          </div>
          <div className="postCardsCard">
            <img src={contactamos} alt="" />
            <h1>Te contactamos</h1>
            <p>
              Nosotros te contactamos para continuar con el proceso de
              consignación. <br/> <br/>
              No tienes que dirigirte a ninguna oficina. Todo lo hacemos por ti.
            </p>
          </div>
          <div className="postCardsCard">
            <img src={visitamos} alt="" />
            <h1>Visitamos tu inmueble</h1>
            <p>
              Acordamos la cita o si prefieres recogemos las llaves de tu
              inmueble y hacemos un inventario, tomamos fotografías, videos e
              instalamos publicidad.
            </p>
          </div>
          <div className="postCardsCard">
            <img src={promocionamos} alt="" />
            <h1>Promocionamos tu inmueble</h1>
            <p>
              Publicamos tu inmueble en los marketplace más reconocidos y de mayor
              tráfico.
            </p>
          </div>
          <div className="postCardsCard">
            <img src={negociacion} alt="" />
            <h1>Negociación</h1>
            <p>
              <b>Información 24/7</b> tienes acceso a los comentarios y visitas
              realizadas a tu inmueble. Así como a las ofertas que existan para que
              tu inmueble empiece a rentar en el menor tiempo posible.
            </p>
          </div>
          <div className="postCardsCard">
            <img src={reciberenta} alt="" />
            <h1>Recibe tu renta</h1>
            <p>
              Aceptada una oferta por tu inmueble, se suscribe contrato y recibirás
              tu renta segura todos los meses.
            </p>
          </div>
        </div>
        <div className="postCardsButton">
          <button
            onClick={() => {
              window.open(
                configuration.whatsAppLink,
                "_blank",
                "noopener,noreferrer"
              );
            }}
          >
            Contacta un asesor
          </button>
        </div>
      </div>
    </section>
  );
}
