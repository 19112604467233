import * as React from "react";
import { Link } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import "./styles.css";
import rendara_logo from "../../../assets/static/icons/logo_horizontal_blanco.svg";
import { configuration } from "../../../config";

export default function Footer() {
  return (
    <footer>
      <Box>
        <Box display="flex" justifyContent={{ md: "center" }} alignItems="center">
          <img src={rendara_logo} alt="Logo de Rendara" className="rendaraLogo" />
        </Box>
        <Grid
          spacing={2}
          container
          justifyContent="space-evenly"
          alignItems="flex-start"
        >
          <Grid
            spacing={2}
            container
            item
            md={8}
            justifyContent="space-evenly"
            alignItems="flex-start"
          >
            <div className="footer-mobile-container">
              <div>
                <div className="footerTitle footerTitleVis">Contenido</div>
              </div>
              <div className="footer-mobile-links-container">
                <div className="footer-mobile-links">
                  <Link to="/quienes-somos">Quienes somos</Link>
                </div>
                <div className="footer-mobile-links">
                  <Link to="/publicar-inmueble">Publica tu inmueble</Link>
                </div>
                <div className="footer-mobile-links">
                  <Link to="/resultado">Encuentra tu nuevo inmueble</Link>
                </div>
                <div className="footer-mobile-links">
                  <Link to="/preguntas-frecuentes">Preguntas frecuentes</Link>
                </div>
                <div className="footer-mobile-links">
                  <Link to="/se-nuestro-aliado">Sé nuestro aliado</Link>
                </div>
                <div className="footer-mobile-links">
                  <a href="/privacidad/Términos_y_Condiciones.pdf"
                    target="_blank">
                    Términos y condiciones
                  </a>
                </div>
                <div className="footer-mobile-links">
                  <a href="/privacidad/Política_de_Tratamiento_de_Datos_Personales_Rendara_SAS.pdf"
                    target="_blank">
                    Política de tratamiento de datos
                  </a>
                </div>
              </div>
            </div>

            <div className="footer-web-container">
              <div className="footer-web-title">
                <div className="footerTitle footerTitleVis">Contenido</div>
              </div>
              <div className="footer-web">
                <div className="footer-web-links-container">
                  <div className="footer-web-links">
                    <Link to="/quienes-somos">Quienes somos</Link>
                  </div>
                  <div className="footer-web-links">
                    <Link to="/publicar-inmueble">Publica tu inmueble</Link>
                  </div>
                  <div className="footer-web-links">
                    <Link to="/resultado">Encuentra tu nuevo inmueble</Link>
                  </div>
                </div>
                <div className="footer-web-links-container">
                  <div className="footer-web-links">
                    <Link to="/preguntas-frecuentes">Preguntas frecuentes</Link>
                  </div>
                  <div className="footer-web-links">
                    <Link to="/se-nuestro-aliado">Sé nuestro aliado</Link>
                  </div>
                </div>
                <div className="footer-web-links-container">
                  <div className="footer-web-links">
                    <a href="/privacidad/Términos_y_Condiciones.pdf"
                      target="_blank">
                      Términos y condiciones
                    </a>
                  </div>
                  <div className="footer-web-links">
                    <a href="/privacidad/Política_de_Tratamiento_de_Datos_Personales_Rendara_SAS.pdf"
                      target="_blank">
                      Política de tratamiento de datos
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <Grid
              spacing={2}
              container
              item
              xs={9}
              md={3}
              justifyContent={{ xs: "flex-start", md: "space-evenly" }}
            >
              <Grid item xs={12} md={10}>
                <div className="footerTitle footerTitleVis">Contenido</div>
              </Grid>
              <Grid item xs={12} md={10}>
                <Link to="/quienes-somos">Quienes somos</Link>
              </Grid>
              <Grid item xs={12} md={10}>
                <Link to="/publicar-inmueble">Publica tu inmueble</Link>
              </Grid>
              <Grid item xs={12} md={10}>
                <Link to="/resultado">Encuentra tu nuevo inmueble</Link>
              </Grid>
            </Grid>
            <Grid
              spacing={2}
              container
              item
              xs={9}
              md={3}
              justifyContent={{ xs: "flex-start", md: "flex-start" }}
            >
              <Grid item className="footerInvTitle">
                <div className="footerTitle">-</div>
              </Grid>
              <Grid item xs={12} md={11}>
                <Link to="/preguntas-frecuentes">Preguntas frecuentes</Link>
              </Grid>
              <Grid item xs={12} md={10}>
                <Link to="/se-nuestro-aliado">Sé nuestro aliado</Link>
              </Grid>
            </Grid>
            <Grid
              spacing={2}
              container
              item
              xs={9}
              md={3}
              justifyContent={{ xs: "flex-start", md: "flex-start" }}
            >
              <Grid item xs={12} md={10} className="footerInvTitle">
                <div className="footerTitle">-</div>
              </Grid>
              <Grid item xs={12}>
                <a href="/privacidad/Términos_y_Condiciones.pdf"
                  target="_blank">
                  Términos y condiciones
                </a>
              </Grid>
              <Grid item xs={12}>
                <a href="/privacidad/Política_de_Tratamiento_de_Datos_Personales_Rendara_SAS.pdf"
                  target="_blank">
                  Política de tratamiento de datos
                </a>
              </Grid>
            </Grid> */}
          </Grid>
          <Grid item xs={9} md={1}>
            <div className="line"></div>
          </Grid>
          <Grid
            spacing={2}
            container
            item
            md={3}
            justifyContent={{ xs: "space-evenly", md: "flex-start" }}
          >
            <Grid item xs={9} md={10}>
              <div className="footerTitle footerTitleVis">Contacto</div>
            </Grid>
            <Grid item xs={9} md={10}>
              <a href={"mailto:" + configuration.rendaraEmail}>{configuration.rendaraEmail}</a>
            </Grid>
            <Grid container item xs={9} md={10}>
              <Grid item xs={3} md={3} justifyContent="space-between">
                <a className="MuiButton-root facebookIcon"
                  href={configuration.facebookLink}
                  target="_blank">
                </a>
              </Grid>
              <Grid item xs={3} md={3} justifyContent="space-between">
                <a className="MuiButton-root instagramIcon"
                  href={configuration.instagramLink}
                  target="_blank">
                </a>
              </Grid>
              <Grid item xs={3} md={3} justifyContent="space-between">
                <a className="MuiButton-root youtubeIcon"
                  href={configuration.youtubeLink}
                  target="_blank">
                </a>
              </Grid>
            </Grid>
            <div className="ma-container">
              <p>
                M.A.<br></br>

                Floridablanca: 0013-2022<br></br>

                Piedecuesta: 03451295<br></br>

                Bucaramanga: 130/2023
              </p>
            </div>
          </Grid>
        </Grid>
      </Box>
    </footer>
  );
}
