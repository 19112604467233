import React, { useEffect } from "react";
import "./styles.css";
import { FavoriteUtils } from "../Utils/FavoriteUtils";
import Property from "../Property";
import axios from "axios";

export default function Favorites() {
  const [properties, setProperties] = React.useState([]);
  let emptyFavorites = properties.length === 0;
  
  useEffect(() => {
    let favoritesCods = FavoriteUtils.get();
    if (favoritesCods.lenght === 0) {
      setProperties([]);
    } else {
      let query = {
        "codigos_inmueble": favoritesCods
      };
      axios.post('/RendaraWebAPI/inmueble/codigos', query)
        .then(function (response) {
          setProperties(response.data);
        })
        .catch(function (error) {
          setProperties([]);
          console.error("Error consultando los inmuebles favoritos: ",
            error.response.status, " ", error.response.data);
        });
    }
  }, []);

  return (
    <section className="favoritesContainer">
      <div className="favoritesText">
        <h1>Favoritos</h1>
        <p>
          Selecciona y mira tus inmuebles favoritos.
        </p>
      </div>
      {!emptyFavorites &&
        <div className="favoritesProperties">
          {properties.map(inmueble =>
            <Property inmueble={inmueble}/>)
          }
        </div>}
      {emptyFavorites &&
        <div className="favoritesEmpty">
          No has seleccionado ningún favorito.
        </div>}
    </section>
  );
}