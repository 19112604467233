import React from "react";
import AboutUsBanner from "../components/AboutUsBanner";
import PassionateAboutNetworking from "../components/PassionateAboutNetworking";

function AboutUs() {
  return (
    <div className="aboutUs">
      <AboutUsBanner />
      <PassionateAboutNetworking />
    </div>
  );
}

export default AboutUs;
